import readXlsxFile from 'read-excel-file'
import _ from 'lodash'
import { isArrayEmpty, isArrayNotEmpty, isObjectReady, isNullOrEmpty } from 'util/utils'

export const status = {
  naoEnviado: 'Pendente.',
  enviando: 'Enviando',
  enviado: 'Enviado!',
  naoSeraEnviado: 'Não será enviado',
  erro: 'Tente novamente.'
}

export const sendProductRequest = (index) => {
  try {
    const produtoEnviado = {
      codigoInterno: this.state.produtos[index][this.state.detailsIndex.codigo + 1] || null,
      nomeProduto: this.state.produtos[index][this.state.detailsIndex.nome + 1] || null,
      descricaoProduto: this.state.produtos[index][this.state.detailsIndex.descricao + 1] || null,
      marca: this.state.produtos[index][this.state.detailsIndex.marca + 1] || null,
      nomeTecnico: this.state.produtos[index][this.state.detailsIndex.nometecnico + 1] || null,
      aplicacao: this.state.produtos[index][this.state.detailsIndex.aplicacao + 1] || null,
      precobase: this.state.produtos[index][this.state.detailsIndex.precobase + 1] || null
    }
    return produtoEnviado
  } catch (error) {
    console.error('Erro em sendProductRequest:', error)
    return null
  }
}
export const naoEnviado = 'Pendente'
export const enviando = 'Enviando'
export const sucesso = 'Sucesso'
export const falha = 'Falha ao enviar'
export const defineIndexOfObject = (rowOfTitles) => {
  try {
    const codigoVariantes = ['Código do Produto', 'Código', 'CodProduto', 'CódigoProduto', 'Codigo']
    const nomeVariantes = ['Nome do Produto', 'Nome', 'Produto']
    const descricaoVariantes = ['Descrição do Produto', 'Descrição', 'DescProduto', 'Desc Produto', 'Desc', 'Descricao']
    const fabricanteVariantes = ['Fabricante', 'fabricante']
    const marcaVariantes = ['Marca', 'marca']
    const nometecnicoVariantes = ['Nome Técnico', 'Técnico', 'nome tecnico', 'Nome tecnico', 'nome técnico']
    const aplicacaoVariantes = ['Aplicação', 'aplicação', 'aplicacao', 'Aplicacao']
    const precoVariantes = ['Preço', 'Preço Base', 'preco', 'preço base', 'preco base', 'Custo', 'custo']
    const imagemVariantes = ['URL', 'URL Imagem', 'Imagem', 'Url', 'url']

    let i = 0

    const produto = {
      status: {
        idStatus: null,
        textStatus: null
      },
      detalhes: {
        categoria: null,
        codigoProduto: null,
        idProduto: null,
        nomeProduto: null,
        descricaoProduto: null,
        marca: null,
        nomeTecnico: null,
        precobase: null,
        pesoBruto: null
      },
      adicionais: {
        fabricante: null,
        aplicacao: null,
        imagem: null
      }
    }

    // Codigo
    while (produto.codigo === null && i < codigoVariantes.length) {
      if (rowOfTitles.indexOf(codigoVariantes[i]) >= 0) {
        produto.codigo = rowOfTitles.indexOf(codigoVariantes[i])
        i = 0
      } else i++
    }

    // Nome
    while (produto.nome === null && i < nomeVariantes.length) {
      if (rowOfTitles.indexOf(nomeVariantes[i]) >= 0) {
        produto.nome = rowOfTitles.indexOf(nomeVariantes[i])
        i = 0
      } else i++
    }

    // Descricao
    while (produto.descricao === null && i < descricaoVariantes.length) {
      if (rowOfTitles.indexOf(descricaoVariantes[i]) >= 0) {
        produto.descricao = rowOfTitles.indexOf(descricaoVariantes[i])
        i = 0
      } else i++
    }

    // Fabricante
    while (produto.fabricante === null && i < fabricanteVariantes.length) {
      if (rowOfTitles.indexOf(fabricanteVariantes[i]) >= 0) {
        produto.fabricante = rowOfTitles.indexOf(fabricanteVariantes[i])
        i = 0
      } else i++
    }

    // Marca
    while (produto.marca === null && i < marcaVariantes.length) {
      if (rowOfTitles.indexOf(marcaVariantes[i]) >= 0) {
        produto.marca = rowOfTitles.indexOf(marcaVariantes[i])
        i = 0
      } else i++
    }

    // Nome Tecnico
    while (produto.nometecnico === null && i < nometecnicoVariantes.length) {
      if (rowOfTitles.indexOf(nometecnicoVariantes[i]) >= 0) {
        produto.nometecnico = rowOfTitles.indexOf(nometecnicoVariantes[i])
        i = 0
      } else i++
    }

    // Aplicacao
    while (produto.aplicacao === null && i < aplicacaoVariantes.length) {
      if (rowOfTitles.indexOf(aplicacaoVariantes[i]) >= 0) {
        produto.aplicacao = rowOfTitles.indexOf(aplicacaoVariantes[i])
        i = 0
      } else i++
    }

    // Preco
    while (produto.precobase === null && i < precoVariantes.length) {
      if (rowOfTitles.indexOf(precoVariantes[i]) >= 0) {
        produto.precobase = rowOfTitles.indexOf(precoVariantes[i])
        i = 0
      } else i++
    }

    // Imagem
    while (produto.imagem === null && i < imagemVariantes.length) {
      if (rowOfTitles.indexOf(imagemVariantes[i]) >= 0) {
        produto.imagem = rowOfTitles.indexOf(imagemVariantes[i])
        i = 0
      } else i++
    }

    return produto
  } catch (error) {
    console.error('Erro em defineIndexOfObject:', error)
    return null
  }
}

export const createProductsObjects = (rows) => {
  const codigoVariantes = ['Código do Produto', 'Código', 'CÓDIGO', 'CodProduto', 'CódigoProduto', 'Codigo']
  const nomeVariantes = ['Nome do Produto', 'Nome', 'Produto']
  const descricaoVariantes = ['Descrição do Produto', 'Descrição', 'DescProduto', 'Desc Produto', 'Desc', 'Descricao', 'Nome descritivo']
  const marcaVariantes = ['Marca', 'marca', 'Fabricante', 'fabricante']
  const nometecnicoVariantes = ['Nome Técnico', 'Técnico', 'nome tecnico', 'Nome tecnico', 'nome técnico']
  const aplicacaoVariantes = ['Aplicação', 'aplicação', 'aplicacao', 'Aplicacao']
  const precoVariantes = ['Preço', 'Preço Base', 'preco', 'preço base', 'preco base', 'Custo', 'custo']

  const produtosIndex = {
    codigoProduto: null,
    nomeProduto: null,
    descricaoProduto: null,
    aplicacao: null,
    nomeTecnico: null,
    marca: null,
    precoBase: null,
    categoria: null
  }

  let produtos = []

  const linhaInicial = 0
  let i = 0
  while (produtosIndex.codigoInterno === null && i < codigoVariantes.length) {
    if (rows[linhaInicial].indexOf(codigoVariantes[i]) >= 0) {
      produtosIndex.codigoInterno = rows[linhaInicial].indexOf(codigoVariantes[i])
      i = 0
    } else i++
    i = 0

    while (produtosIndex.nomeProduto === null && i < nomeVariantes.length) {
      if (rows[linhaInicial].indexOf(nomeVariantes[i]) >= 0) {
        produtosIndex.nomeProduto = rows[linhaInicial].indexOf(nomeVariantes[i])
        i = 0
      } else i++
    }
    i = 0

    while (produtosIndex.marca === null && i < marcaVariantes.length) {
      if (rows[linhaInicial].indexOf(marcaVariantes[i]) >= 0) {
        produtosIndex.marca = rows[linhaInicial].indexOf(marcaVariantes[i])
        i = 0
      } else i++
    }
    i = 0

    while (produtosIndex.descricaoProduto === null && i < descricaoVariantes.length) {
      if (rows[linhaInicial].indexOf(descricaoVariantes[i]) >= 0) {
        produtosIndex.descricaoProduto = rows[linhaInicial].indexOf(descricaoVariantes[i])
        i = 0
      } else i++
    }
    i = 0

    while (produtosIndex.nomeTecnico === null && i < nometecnicoVariantes.length) {
      if (rows[linhaInicial].indexOf(nometecnicoVariantes[i]) >= 0) {
        produtosIndex.nomeTecnico = rows[linhaInicial].indexOf(nometecnicoVariantes[i])
        i = 0
      } else i++
    }
    i = 0

    while (produtosIndex.aplicacao === null && i < aplicacaoVariantes.length) {
      if (rows[linhaInicial].indexOf(aplicacaoVariantes[i]) >= 0) {
        produtosIndex.aplicacao = rows[linhaInicial].indexOf(aplicacaoVariantes[i])
        i = 0
      } else i++
    }
    i = 0

    while (produtosIndex.precoBase === null && i < precoVariantes.length) {
      if (rows[linhaInicial].indexOf(precoVariantes[i]) >= 0) {
        produtosIndex.precoBase = rows[linhaInicial].indexOf(precoVariantes[i])
        i = 0
      } else i++
    }
    i = 0
  }
  produtos = rows.map((row) => {
    const novoProduto = {
      detalhes: {
        codigoInterno: isNaN(row[produtosIndex.codigoInterno]) ? row[produtosIndex.codigoInterno] : parseInt(row[produtosIndex.codigoInterno]),
        nomeProduto: row[produtosIndex.nomeProduto] || null,
        descricaoProduto: row[produtosIndex.descricaoProduto] || null,
        aplicacao: row[produtosIndex.aplicacao] || null,
        nomeTecnico: row[produtosIndex.nomeTecnico] || null,
        marca: row[produtosIndex.marca] || null,
        precoBase: row[produtosIndex.precoBase] || null
      }
    }
    return novoProduto
  })

  return produtos
}

export const createProductsObjectsAnuncio = (rows) => {
  const idVariantes = ['Id do Produto', 'id', 'ID', 'id produto', 'id do produto']
  const nomeVariantes = ['Nome do Produto', 'Nome', 'Produto', 'nome']
  const unidadeVariantes = ['Unidade', 'unidade', 'Und', 'und']
  const quantidadeVariantes = ['Quantidade', 'quantidade', 'Qtd', 'qtd', 'Qtde.', 'qtde.']
  const precoVariantes = ['Preço', 'preço', 'preco', 'Preco', 'preço unitário', 'preco unitario', 'Preço Unitário', 'Preco Unitario', 'Custo', 'custo']

  const produtosIndex = {
    prodNewId: null,
    prodNewName: null,
    prodNewUnd: null,
    totalQtde: null,
    prodNewPrice: null
  }

  let produtos = []

  const linhaInicial = 0

  let i = 0
  while (produtosIndex.prodNewId === null && i < idVariantes.length) {
    if (rows[linhaInicial].indexOf(idVariantes[i]) >= 0) {
      produtosIndex.prodNewId = rows[linhaInicial].indexOf(idVariantes[i])
      i = 0
    } else i++
  }

  i = 0
  while (produtosIndex.prodNewName === null && i < nomeVariantes.length) {
    if (rows[linhaInicial].indexOf(nomeVariantes[i]) >= 0) {
      produtosIndex.prodNewName = rows[linhaInicial].indexOf(nomeVariantes[i])
      i = 0
    } else i++
  }

  i = 0
  while (produtosIndex.prodNewUnd === null && i < unidadeVariantes.length) {
    if (rows[linhaInicial].indexOf(unidadeVariantes[i]) >= 0) {
      produtosIndex.prodNewUnd = rows[linhaInicial].indexOf(unidadeVariantes[i])
      i = 0
    } else i++
  }

  i = 0
  while (produtosIndex.totalQtde === null && i < quantidadeVariantes.length) {
    if (rows[linhaInicial].indexOf(quantidadeVariantes[i]) >= 0) {
      produtosIndex.totalQtde = rows[linhaInicial].indexOf(quantidadeVariantes[i])
      i = 0
    } else i++
  }

  i = 0
  while (produtosIndex.prodNewPrice === null && i < precoVariantes.length) {
    if (rows[linhaInicial].indexOf(precoVariantes[i]) >= 0) {
      produtosIndex.prodNewPrice = rows[linhaInicial].indexOf(precoVariantes[i])
      i = 0
    }

    produtos = rows.map((row) => {
      const novoProduto = {
        prodNewId: row[produtosIndex.prodNewId] || null,
        prodNewName: row[produtosIndex.prodNewName] || null,
        prodNewUnd: row[produtosIndex.prodNewUnd] || null,
        totalQtde: row[produtosIndex.totalQtde] || null,
        prodNewPrice: row[produtosIndex.prodNewPrice] || isNullOrEmpty,
        notRegistered: true
      }
      return novoProduto
    })
    return produtos
  }
}

export const addStatusAndOptions = (produtos) => {
  try {
    return produtos.map((produto, index) => {
      const statusInicial = index === 0 ? 'Status' : status.naoEnviado
      return {
        ...produto,
        controle: {
          descricaoStatus: statusInicial,
          paraEnvio: index !== 0,
          idInterno: `produto-${index}`
        }
      }
    })
  } catch (error) {
    console.error('Erro durante a adição de status e opções aos produtos:', error)
    return []
  }
}

// leitura da tabela
function importarProdutos (rows, isCatalogo) {
  try {
    const processedRows = rows.map((row, i) => {
      if (i === 0) return {}
      if (isCatalogo) {
        const [codigoInterno, nomeProduto, descricao, codigoDeBarras, unidadeBase, precoBase] = row
        return {
          codigoInterno,
          nomeProduto,
          descricao,
          codigoDeBarras,
          unidadeBase,
          precoBase: precoBase || 0
        }
      } else {
        const [codigoInterno, nomeProduto, quantidade, precoBase, unidadeBase, vencimento, numLote] = row
        return {
          codigoInterno,
          nomeProduto,
          quantidade,
          precoBase: precoBase || 0,
          unidadeBase,
          vencimento,
          numLote
        }
      }
    })
    return processedRows.filter((row) => !isNullOrEmpty(row.codigoInterno))
  } catch (error) {
    console.error('Erro em importarProdutos:', error)
    return []
  }
}

export async function handleOnFileChangeEventAsync (e, callback, isCatalogo) {
  try {
    const fileExists = !isNullOrEmpty(e.target) && e.target.files !== undefined && e.target.files.length > 0

    if (fileExists && callback) {
      const rows = await readXlsxFile(e.target.files[0])
      if (isArrayNotEmpty(rows)) callback(importarProdutos(rows, isCatalogo))
    }
  } catch (error) {
    console.error('Erro em handleOnFileChangeEventAsync:', error)
  }
}

export const getProdutosFiltrados = (produtos, categorias, categoria, subCategoria) => {
  try {
    let lista = produtos

    if (subCategoria && subCategoria.value > 0 && categoria) {
      lista = produtos.filter((produto) => produto.categoria === subCategoria.value || produto.categoria === categoria.value)
    } else if (categoria && categoria.value > 0) {
      const categoriaSelecionada = categorias.find((x) => x.idCategoria === categoria.value)

      if (categoriaSelecionada && isArrayNotEmpty(categoriaSelecionada.filhos)) {
        lista = produtos.filter((produto) => produto.categoria === categoria.value || categoriaSelecionada.filhos.some((x) => x.idCategoria === produto.categoria))
      } else {
        lista = produtos.filter((produto) => produto.categoria === categoria.value)
      }
    }

    return lista
  } catch (error) {
    console.error('Erro em getProdutosFiltrados:', error)
    return []
  }
}

export const orderBy = (produtos) => {
  try {
    return _.orderBy(
      produtos.filter((produto) => produto.nomeProduto),
      ['nomeProduto'],
      ['asc']
    )
  } catch (error) {
    console.error('Ocorreu um erro ao ordenar os produtos:', error)
    return produtos
  }
}

export const opcoesUnidadeProduto = () => ['CX', 'KG', 'UN', 'SACO']

export const opcoesUnidadeProdutoSelect = () => {
  try {
    return opcoesUnidadeProduto().map((item) => ({ value: item, label: item }))
  } catch (error) {
    console.error('Ocorreu um erro ao criar as opções de unidade de produto:', error)
    return []
  }
}

export const produtosToSelect = (produtos) => {
  try {
    return produtos.map((p) => ({ value: p.idProduto, label: p.nomeProduto }))
  } catch (error) {
    console.error('Ocorreu um erro ao converter os produtos para seleção:', error)
    return []
  }
}

export const preencherObjComProdutoInterno = (produtos) => {
  try {
    if (isArrayEmpty(produtos)) {
      return []
    }

    const produtosFiltrados = produtos.filter((p) => isObjectReady(p.produto) && p.produto.nomeProduto)

    if (isArrayNotEmpty(produtosFiltrados)) {
      produtosFiltrados.forEach((element) => {
        element.nomeProduto = element.produto.nomeProduto
      })
    }

    return produtos
  } catch (error) {
    console.error('Ocorreu um erro ao preencher o objeto com o produto interno:', error)
    return produtos
  }
}
