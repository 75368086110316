import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ProdutoItem from './produtoItem'
import ProdutoNovo from './produtoNovo'
import { isArrayEmpty } from 'util/utils'

const ProdutosCadastradosList = ({ anuncioProdutos, isEstoqueProduto, isPrecoProduto, produtos, removido, onRemove, onChange, onRestore, onAdd, isSale }) => {
  const [onAddMode, setOnAddMode] = useState(false)

  const renderProdutoInserido = (produto) => (
    <ProdutoItem
      key={`itemListProduto_${produto.idProduto}`}
      itemProduto={produto}
      removido={removido}
      onChange={onChange}
      onRemove={onRemove}
      onRestore={onRestore}
      isEstoqueProduto={isEstoqueProduto}
      isPrecoProduto={isPrecoProduto}
      isRegistered={produto.registered}
      isSale={isSale}
    />
  )
  useEffect(() => {
    if (onAddMode) setOnAddMode(false)
  }, [anuncioProdutos])
  const handleOnAdd = (produto) => {
    onAdd(produto)
  }
  const renderAddNew = () => {
    if (onAddMode) {
      return <ProdutoNovo produtos={produtos} onCancel={() => setOnAddMode(false)} onAdd={handleOnAdd} />
    }

    return renderAddNewButton()
  }
  const formatProducts = (produto) => {
    if (produto.valor && !produto.preco) {
      produto.preco = produto.valor
      produto.valor = produto.valor * produto.quantidade
    }
    return produto
  }
  const renderAddNewButton = () => (
    <div className='text-right'>
      <button className='btn btn-sm btn-primary' onClick={() => setOnAddMode(true)}>
        Adicionar novo produto manualmente
      </button>
    </div>
  )

  if (isArrayEmpty(anuncioProdutos)) {
    return !removido && renderAddNew()
  }
  const formatedProducts = anuncioProdutos.map((produto) => formatProducts(produto))
  return (
    <>
      {formatedProducts.map((produto) => renderProdutoInserido(produto))}
      {!removido && renderAddNew()}
    </>
  )
}

ProdutosCadastradosList.propTypes = {
  anuncioProdutos: PropTypes.array.isRequired,
  isEstoqueProduto: PropTypes.bool.isRequired,
  isPrecoProduto: PropTypes.bool.isRequired,
  produtos: PropTypes.array,
  removido: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onRestore: PropTypes.func,
  onAdd: PropTypes.func,
  isSale: PropTypes.bool
}

export default ProdutosCadastradosList
