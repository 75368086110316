import React, { Component, createRef } from 'react'
import { FormControl, InputGroup, Button } from 'react-bootstrap'
import Fuse from 'fuse.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { screenSize, isNotNullOrEmpty } from 'util/utils'

import './style.css'

const defaultFuseOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: ['nomeProduto']
}

export default class SearchBar extends Component {
  fuse

  constructor (props) {
    super(props)

    this.state = {
      value: '',
      loading: false,
      screenSize: 'md'
    }

    this.inputRef = createRef()
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount () {
    this.setState({ screenSize: screenSize() })
    window.addEventListener('resize', this.onResize, false)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize, false)
  }

  get isMobile () {
    return this.state.screenSize !== 'lg'
  }

  get hasSearch () {
    return isNotNullOrEmpty(this.state.value) || this.props.hasSearch || false
  }

  get options () {
    return this.props.options || defaultFuseOptions
  }

  onResize () {
    this.setState({ screenSize: screenSize() })
  }

  buttonClearSearch () {
    if (this.hasSearch) {
      return (
        <Button className={`${this.isMobile ? '' : 'my-0 ml-1 mr-0'}`} variant='outline-danger' onClick={this.cleanSearch.bind(this)}>
          Limpar <FontAwesomeIcon icon={faTimes} size='sm' className='ml-1' />
        </Button>
      )
    }

    return <></>
  }

  setFocus = () => {
    this.inputRef.current.focus() // Use the ref to set focus
  }

  cleanSearch () {
    this.setState({ value: '' })
    this.searchValues()
    this.setFocus()
  }

  fuseInstance () {
    this.fuse = new Fuse(this.props.items, this.options)
  }

  keyBind (e) {
    if (e.key === 'Enter') this.searchValues(this.state.value)
  }

  searchModel (e) {
    this.setState({ value: e.target.value })
  }

  searchValues (value) {
    const { onSearchItems } = this.props

    if (!this.state || this.state.loading) return false

    const query = (value && value.trim()) || ''
    if (!query) {
      return onSearchItems({ result: this.props.items, query })
    }

    this.setState({ loading: true })

    if (!this.fuse) this.fuseInstance()
    const result = this.fuse.search(query).map((result) => result.item)

    this.setState({ loading: false })

    onSearchItems({ result, query })

    return result
  }

  render () {
    return (
      <InputGroup className='search-bar-container'>
        <FormControl
          type='text'
          ref={this.inputRef}
          placeholder={this.props.placeholder || 'Buscar'}
          value={this.state.value}
          onChange={this.searchModel.bind(this)}
          onKeyDown={this.keyBind.bind(this)}
        />
        {this.buttonClearSearch()}
        <Button className={`${this.isMobile ? '' : 'my-0 ml-1 mr-0'}`} onClick={() => this.searchValues(this.state.value)}>
          Buscar <FontAwesomeIcon icon={faSearch} size='sm' className='ml-1' />
        </Button>
      </InputGroup>
    )
  }
}
