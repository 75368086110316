export default {
  DOACAO: {
    ANUNCIO: {
      LOAD: {
        BEGIN: 'REDUCER_DR_ANUNCIO_LOAD_BEGIN',
        SUCCESS: 'REDUCER_DR_ANUNCIO_LOAD_SUCCESS',
        FAILURE: 'REDUCER_DR_ANUNCIO_LOAD_FAILURE'
      },
      DIA: {
        BEGIN: 'DR_ANUNCIO_DIA_BEGIN',
        SUCCESS: 'DR_ANUNCIO_DIA_SUCCESS',
        FAILURE: 'DR_ANUNCIO_DIA_FAILURE'
      }
    },
    COLETA: {
      DIA: {
        BEGIN: 'DR_COLETA_DIA_BEGIN',
        SUCCESS: 'DR_COLETA_DIA_SUCCESS',
        FAILURE: 'DR_COLETA_DIA_FAILURE'
      }
    },
    RESERVA: {
      DIA: {
        BEGIN: 'DR_RESERVA_DIA_BEGIN',
        SUCCESS: 'DR_RESERVA_DIA_SUCCESS',
        FAILURE: 'DR_RESERVA_DIA_FAILURE'
      }
    },
    CONTRACT_TYPE_SALES: {
      BEGIN: 'DRADMIN_CONTRACT_TYPE_SALES_BEGIN',
      SUCCESS: 'DRADMIN_CONTRACT_TYPE_SALES_SUCCESS',
      FAILURE: 'DRADMIN_CONTRACT_TYPE_SALES_FAILURE'
    },
    CONTRACT_TYPE_DONATION: {
      BEGIN: 'DRADMIN_CONTRACT_TYPE_DONATION_BEGIN',
      SUCCESS: 'DRADMIN_CONTRACT_TYPE_DONATION_SUCCESS',
      FAILURE: 'DRADMIN_CONTRACT_TYPE_DONATION_FAILURE'
    }
  },
  VENDA: {
    ANUNCIO: {
      LOAD: {
        BEGIN: 'REDUCER_VR_ANUNCIO_LOAD_BEGIN',
        SUCCESS: 'REDUCER_VR_ANUNCIO_LOAD_SUCCESS',
        FAILURE: 'REDUCER_VR_ANUNCIO_LOAD_FAILURE'
      },
      DIA: {
        BEGIN: 'VR_ANUNCIO_DIA_BEGIN',
        SUCCESS: 'VR_ANUNCIO_DIA_SUCCESS',
        FAILURE: 'VR_ANUNCIO_DIA_FAILURE'
      }
    },
    ENTREGA: {
      DIA: {
        BEGIN: 'VENDA_ENTREGA_COMPRADOR_DIA_BEGIN',
        SUCCESS: 'VENDA_ENTREGA_COMPRADOR_DIA_SUCCESS',
        FAILURE: 'VENDA_ENTREGA_COMPRADOR_DIA_FAILURE'
      },
      COMPRADOR: {
        LOAD: {
          BEGIN: 'VENDA_ENTREGA_COMPRADOR_LOAD_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_COMPRADOR_LOAD_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_COMPRADOR_LOAD_FAILURE'
        },
        BYID: {
          BEGIN: 'VENDA_ENTREGA_COMPRADOR_BYID_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_COMPRADOR_BYID_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_COMPRADOR_BYID_FAILURE'
        },
        POST: {
          BEGIN: 'VENDA_ENTREGA_COMPRADOR_POST_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_COMPRADOR_POST_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_COMPRADOR_POST_FAILURE'
        },
        ASSINATURA: {
          BEGIN: 'VENDA_ENTREGA_COMPRADOR_ASSINATURA_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_COMPRADOR_ASSINATURA_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_COMPRADOR_ASSINATURA_FAILURE'
        }
      },
      ANUNCIANTE: {
        LOAD: {
          BEGIN: 'VENDA_ENTREGA_ANUNCIANTE_LOAD_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_ANUNCIANTE_LOAD_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_ANUNCIANTE_LOAD_FAILURE'
        },
        BYID: {
          BEGIN: 'VENDA_ENTREGA_ANUNCIANTE_BYID_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_ANUNCIANTE_BYID_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_ANUNCIANTE_BYID_FAILURE'
        },
        POST: {
          BEGIN: 'VENDA_ENTREGA_ANUNCIANTE_POST_BEGIN',
          SUCCESS: 'VENDA_ENTREGA_ANUNCIANTE_POST_SUCCESS',
          FAILURE: 'VENDA_ENTREGA_ANUNCIANTE_POST_FAILURE'
        }
      }
    },
    RESERVA: {
      BYID: {
        BEGIN: 'VENDA_RESERVA_BYID_BEGIN',
        SUCCESS: 'VENDA_RESERVA_BYID_SUCCESS',
        FAILURE: 'VENDA_RESERVA_BYID_FAILURE'
      },
      DIA: {
        BEGIN: 'VENDA_RESERVA_DIA_BEGIN',
        SUCCESS: 'VENDA_RESERVA_DIA_SUCCESS',
        FAILURE: 'VENDA_RESERVA_DIA_FAILURE'
      },
      EDIT: {
        BEGIN: 'EDIT_RESERVA_BEGIN',
        SUCCESS: 'EDIT_RESERVA_SUCCESS',
        FAILURE: 'EDIT_RESERVA_FAILURE'
      }
    },
    CONTRACT_TYPE_DONATION: {
      BEGIN: 'VRADMIN_CONTRACT_TYPE_DONATION_BEGIN',
      SUCCESS: 'VRADMIN_CONTRACT_TYPE_DONATION_SUCCESS',
      FAILURE: 'VRADMIN_CONTRACT_TYPE_DONATION_FAILURE'
    }
  },
  CADASTRO: {
    USUARIO: {
      BEGIN: 'CADASTRO_USUARIO_BEGIN',
      SUCCESS: 'CADASTRO_USUARIO_SUCCESS',
      FAILURE: 'CADASTRO_USUARIO_FAILURE'
    },
    EMPRESA: {
      LOAD: {
        BEGIN: 'CADASTRO_EMPRESA_LOAD_BEGIN',
        SUCCESS: 'CADASTRO_EMPRESA_LOAD_SUCCESS',
        FAILURE: 'CADASTRO_EMPRESA_LOAD_FAILURE'
      },
      BYID: {
        BEGIN: 'CADASTRO_EMPRESA_BYID_BEGIN',
        SUCCESS: 'CADASTRO_EMPRESA_BYID_SUCCESS',
        FAILURE: 'CADASTRO_EMPRESA_BYID_FAILURE'
      },
      POST: {
        BEGIN: 'CADASTRO_EMPRESA_POST_BEGIN',
        SUCCESS: 'CADASTRO_EMPRESA_POST_SUCCESS',
        FAILURE: 'CADASTRO_EMPRESA_POST_FAILURE'
      }
    },
    DESEJOS: {
      BEGIN: 'CADASTRO_DESEJOS_BEGIN',
      SUCCESS: 'CADASTRO_DESEJOS_SUCCESS',
      FAILURE: 'CADASTRO_DESEJOS_FAILURE'
    },
    STAGE: {
      SET: 'CADASTRO_STAGE_SET',
      RESET: 'CADASTRO_STAGE_RESET',
      TOKEN: 'CADASTRO_STAGE_SET_TOKEN'
    },
    CLEAN: 'CADASTRO_CLEAN'
  },
  EXTERNAL: {
    CNPJ: {
      BEGIN: 'EXTERNAL_CNPJ_BEGIN',
      SUCCESS: 'EXTERNAL_CNPJ_SUCCESS',
      FAILURE: 'EXTERNAL_CNPJ_FAILURE'
    },
    CEP: {
      BEGIN: 'EXTERNAL_CEP_BEGIN',
      SUCCESS: 'EXTERNAL_CEP_SUCCESS',
      FAILURE: 'EXTERNAL_CEP_FAILURE'
    }
  },
  PRODUCTS: {
    CATEGORIES: {
      LOAD: {
        BEGIN: 'LOAD_CATEGORIES_BEGIN',
        SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
        FAILURE: 'LOAD_CATEGORIES_FAIL'
      }
    },
    GET_ALL_PRODS: {
      LOAD: {
        BEGIN: 'LOAD_GET_ALL_PRODS_BEGIN',
        SUCCESS: 'LOAD_GET_ALL_PRODS_SUCCESS',
        FAILURE: 'LOAD_GET_ALL_PRODS_FAIL'
      }
    },
    GET_BY_CATEG: {
      LOAD: {
        BEGIN: 'LOAD_GET_BY_CATEG_BEGIN',
        SUCCESS: 'LOAD_GET_BY_CATEG_SUCCESS',
        FAILURE: 'LOAD_GET_BY_CATEG_FAIL'
      }
    },
    GET_PRODUCT_BY_ID: {
      LOAD: {
        BEGIN: 'LOAD_GET_PRODUCT_BY_ID_BEGIN',
        SUCCESS: 'LOAD_GET_PRODUCT_BY_ID_SUCCESS',
        FAILURE: 'LOAD_GET_PRODUCT_BY_ID_FAIL'
      }
    },
    IMAGENS: {
      POST: {
        BEGIN: 'POST_IMAGENS_BEGIN',
        SUCCESS: 'POST_IMAGENS_SUCCESS',
        FAILURE: 'POST_IMAGENS_FAILURE'
      },
      DELETE: {
        BEGIN: 'DELETE_IMAGENS_BEGIN',
        SUCCESS: 'DELETE_IMAGENS_SUCCESS',
        FAILURE: 'DELETE_IMAGENS_FAILURE'
      }
    }
  }
}
