import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'react-bootstrap'

import Loading from 'components/loading'
import Refresh from 'components/refresh'
import { TelaSucesso, Link, Mensagem } from 'components/telaSucesso'
import TelaDeErro from 'components/telaDeErro'
import ObservationTextArea from 'components/observationTextArea'
import BotaoSalvar from 'components/BotaoSalvar'

import { addAnuncio, updateAnuncio, loadAnuncioById, clearError as clearErrorAnuncio } from 'store/anuncio/action'
import { addAnuncio as addAnuncioDoacao, clearError, updateAnuncio as updateAnuncioDoacao, loadAnuncioById as loadAnuncioDoacaoById } from 'store/anuncio-doacao/action'
import { loadContratoById as loadContratoDoacaoById } from 'store/contrato-doacao/action'
import { loadContratoById } from 'store/contrato/action'
import { loadProdutos } from 'store/produto/actions'
import EstoqueSwitchComPreco from 'components/produto/estoqueSwitchComPreco'
import { isArrayEmpty, isObjectReady } from 'util/utils'
import { getDateTime, getNow, addMinutes } from 'util/date'
import { Screen } from 'components/style'

import { URL_VENDA_ANUNCIO_LISTAR, URL_VENDA_ANUNCIO_EDITAR } from 'store/anuncio/urls'

import { AssinaturasBox, HorarioBox, GrupoBox, ProdutosList, FormasDePagamentoBox, ImportarPlanilha } from 'components/anuncio'

import {
  isFormValid,
  isReservaDataInicioValid as isReservaInicioValid,
  isEntregaDataInicioValid as isEntregaInicioValid,
  isReservaDataFimValid as isReservaFimValid,
  isEntregaDataFimValid as isEntregaFimValid
} from 'util/validacoes'

class AnuncioNovo extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      anuncio: {
        idContrato: null,
        dataInicioReserva: getDateTime(addMinutes(getNow(), 2)),
        dataFimReserva: getDateTime(addMinutes(getNow(), 122)),
        dataInicioEntrega: getDateTime(addMinutes(getNow(), 5)),
        dataFimEntrega: getDateTime(addMinutes(getNow(), 125)),
        anuncioProdutos: [],
        observacaoAnuncio: undefined,
        isEstoqueProduto: false,
        isPrecoProduto: false,
        ativo: true,
        anuncioProdutosRemovidos: []
      },
      isEdit: undefined
    }
  }

  async componentDidMount () {
    const {
      dispatch,
      loadContratoById,
      loadProdutos,
      match: { params }
    } = this.props
    const { anuncio } = this.state
    const { isDoador } = this.props
    const { id } = params
    const isNew = window.location.href.includes('novo')
    if (isNew) {
      if (isDoador) {
        const { payload } = await dispatch(loadContratoDoacaoById(id))
        console.log(payload)
        this.setState({ anuncio: { ...anuncio, anuncioProdutos: payload.produtos, idContrato: id, isEstoqueProduto: payload.isEstoqueProduto }, isEdit: false })
      } else {
        const { payload } = await dispatch(loadContratoById(id))
        payload.produtos = payload.produtos.map((p) => ({ ...p, precoAnuncio: p.preco || 0, quantidadeAnuncio: p.quantidade || 0 }))
        console.log('payload', payload)
        this.setState({
          anuncio: { ...anuncio, anuncioProdutos: payload.produtos, idContrato: id, isEstoqueProduto: payload.isEstoqueProduto, isPrecoProduto: payload.isPrecoProduto },
          isEdit: false
        })
        console.log('payload', payload)
      }
    } else {
      if (isDoador) {
        const { payload } = await dispatch(loadAnuncioDoacaoById(id))
        const produtos = payload.anuncioProdutos.map((ap) => ({ ...ap, registered: true }))
        dispatch(loadContratoDoacaoById(payload.idContrato))
        this.setState({ anuncio: { ...payload, anuncioProdutos: produtos }, isEdit: true })
      } else {
        const { payload } = await dispatch(loadAnuncioById(id))
        const produtos = payload.anuncioProdutos.map((ap) => ({ ...ap, registered: true }))
        dispatch(loadContratoById(payload.idContrato))
        this.setState({ anuncio: { ...payload, anuncioProdutos: produtos }, isEdit: true })
      }
    }

    dispatch(loadProdutos())
  }

  handleOnChangeInicioReserva = (inicioReserva) => this.setState({ anuncio: { ...this.state.anuncio, dataInicioReserva: inicioReserva } })
  handleOnChangeFimReserva = (fimReserva) => this.setState({ anuncio: { ...this.state.anuncio, dataFimReserva: fimReserva } })
  handleOnChangeInicioEntrega = (inicioEntrega) => this.setState({ anuncio: { ...this.state.anuncio, dataInicioEntrega: inicioEntrega } })
  handleOnChangeFimEntrega = (fimEntrega) => this.setState({ anuncio: { ...this.state.anuncio, dataFimEntrega: fimEntrega } })

  renderAssinaturas = () => !isArrayEmpty(this.props.contrato.assinaturas) && <AssinaturasBox assinaturas={this.props.contrato.assinaturas} />
  renderGrupos = () => !isArrayEmpty(this.props.contrato.grupos) && <GrupoBox grupos={this.props.contrato.grupos} />

  handleOnImportarPlanilha = (anunciosProdutos) => {
    const { anuncio } = this.state

    if (!isArrayEmpty(anunciosProdutos)) {
      this.setState({ anuncio: { ...anuncio, anuncioProdutos: [...anunciosProdutos] } })
    }
  }

  alterarAnuncioProduto = (anuncioBase, payload) => ({
    ...anuncioBase,
    ...payload,
    valor: payload.preco * payload.quantidade || 0
  })

  handleOnChangeProduto = (payload) => {
    const { idProduto, quantidade, preco } = payload
    const { anuncio } = this.state

    const anuncioProdutos = anuncio.anuncioProdutos.map((ap) => {
      if (ap.idProduto === idProduto) {
        const precoNumber = Number(preco)
        if (ap.preco !== precoNumber) {
          const precoAnterior = ap.preco
          payload.precoAnterior = precoAnterior
        }
        if (ap.quantidade !== quantidade) {
          const quantidadeAnterior = ap.quantidade || 0
          payload.quantidadeAnterior = quantidadeAnterior
        }
        return this.alterarAnuncioProduto(ap, payload)
      } else return ap
    })
    this.setState({ anuncio: { ...anuncio, anuncioProdutos: [...anuncioProdutos] } })
  }

  handleOnRemoveProduto = (idProduto) => {
    const { anuncio } = this.state

    this.setState({
      anuncio: {
        ...anuncio,
        anuncioProdutosRemovidos: [...anuncio.anuncioProdutosRemovidos, ...anuncio.anuncioProdutos.filter((ap) => ap.idProduto === idProduto)],
        anuncioProdutos: [...anuncio.anuncioProdutos.filter((ap) => ap.idProduto !== idProduto)]
      }
    })
  }

  handleOnRestoreProduto = (idProduto) => {
    const { anuncio } = this.state

    this.setState({
      anuncio: {
        ...anuncio,
        anuncioProdutosRemovidos: [...anuncio.anuncioProdutosRemovidos.filter((ap) => ap.idProduto !== idProduto)],
        anuncioProdutos: [...anuncio.anuncioProdutos, ...anuncio.anuncioProdutosRemovidos.filter((ap) => ap.idProduto === idProduto)]
      }
    })
  }

  handleOnAddProdutoManual = (produto) => {
    const {
      anuncio,
      anuncio: { isPrecoProduto, isEstoqueProduto }
    } = this.state
    console.log('produto', produto)
    const novoProduto = {
      preco: isPrecoProduto ? produto.precoBase : 0,
      quantidade: isEstoqueProduto ? produto.estoque : 0,
      unidade: 'KG',
      idProduto: produto.idProduto,
      produto
    }
    console.log('novoProduto', novoProduto)
    const anuncioProdutos = [...anuncio.anuncioProdutos, novoProduto]
    this.setState({
      anuncio: {
        ...anuncio,
        anuncioProdutos
      }
    })
  }

  renderAnuncioForm = () => {
    const { anuncio, isEdit } = this.state
    const {
      contrato,
      produtos,
      anuncio: { isPosting },
      isDoador
    } = this.props
    console.log('state', this.state)
    const backurl = `${isDoador ? '/doacao' : '/venda'}/contrato/listar/todos`
    return (
      <Screen back={{ to: backurl, title: 'Contrato' }}>
        <Container>
          <Row xs={12}>
            {this.renderAssinaturas()}
            {this.renderGrupos()}
          </Row>
          <hr />
          <Row xs={12}>
            <Col xs={12}>
              <HorarioBox
                titulo='Reserva'
                disabled={false}
                inicio={anuncio.dataInicioReserva}
                fim={anuncio.dataFimReserva}
                onChangeInicio={this.handleOnChangeInicioReserva}
                onChangeFim={this.handleOnChangeFimReserva}
                invalidInicio={isEdit ? false : !isReservaInicioValid(anuncio)}
                invalidFim={!isReservaFimValid(anuncio, isEdit)}
              />
            </Col>
            <Col xs={12}>
              <HorarioBox
                titulo='Entrega'
                disabled={false}
                inicio={anuncio.dataInicioEntrega}
                fim={anuncio.dataFimEntrega}
                onChangeInicio={this.handleOnChangeInicioEntrega}
                onChangeFim={this.handleOnChangeFimEntrega}
                invalidInicio={!isEntregaInicioValid(anuncio)}
                invalidFim={!isEntregaFimValid(anuncio, isEdit)}
              />
            </Col>
            <Col xs={12}>
              <EstoqueSwitchComPreco
                isEstoque={anuncio.isEstoqueProduto}
                onChangeEstoque={(isEstoque) => {
                  const anuncioProdutos = anuncio.anuncioProdutos.map((ap) => {
                    const quantidade = isEstoque ? ap.produto.estoque : ap.quantidadeAnterior
                    const quantidadeAnterior = ap.quantidade !== quantidade ? ap.quantidade : ap.quantidadeAnterior
                    return { ...ap, quantidade, quantidadeAnterior }
                  })

                  this.setState({ anuncio: { ...anuncio, anuncioProdutos, isEstoqueProduto: isEstoque } })
                }}
                isPreco={anuncio.isPrecoProduto}
                isSale={!isDoador}
                onChangePreco={(isPreco) => {
                  const anuncioProdutos = anuncio.anuncioProdutos.map((ap) => {
                    const preco = isPreco ? ap.produto.precoBase : ap.precoAnterior
                    const precoAnterior = ap.preco !== preco ? ap.preco : ap.precoAnterior
                    return { ...ap, preco, precoAnterior }
                  })
                  this.setState({ anuncio: { ...anuncio, anuncioProdutos, isPrecoProduto: isPreco } })
                }}
              />
            </Col>
            <Col xs={12}>
              <ProdutosList
                anuncioProdutos={anuncio.anuncioProdutos}
                produtos={produtos.filter((p) => anuncio.anuncioProdutos && !anuncio.anuncioProdutos.some((ap) => ap.idProduto === p.idProduto))}
                onChange={this.handleOnChangeProduto}
                onRemove={this.handleOnRemoveProduto}
                onAdd={this.handleOnAddProdutoManual}
                isEstoqueProduto={anuncio.isEstoqueProduto}
                isPrecoProduto={anuncio.isPrecoProduto}
                isSale={!isDoador}
              />
            </Col>
            <Col xs={12}>
              <ImportarPlanilha anuncioProdutos={anuncio.anuncioProdutos} produtos={produtos} onImportar={this.handleOnImportarPlanilha} />
            </Col>
            <Col xs={12}>
              <ObservationTextArea
                onChange={(e) => this.setState({ anuncio: { ...anuncio, observacaoAnuncio: e.target.value } })}
                maxchars={500}
                labeltextarea='Observações:'
                textarearows='3'
                value={this.state.anuncio.observacaoAnuncio || ''}
              />
            </Col>
            <Col xs={12}>
              <FormasDePagamentoBox formasDePagamento={contrato.formasDePagamento} />
            </Col>
            <Col xs={12}>
              <ProdutosList
                anuncioProdutos={anuncio.anuncioProdutosRemovidos}
                onRestore={this.handleOnRestoreProduto}
                isEstoqueProduto={anuncio.isEstoqueProduto}
                isPrecoProduto={anuncio.isPrecoProduto}
                isSale
                removido
              />
            </Col>
            <Col xs={12}>
              <BotaoSalvar onClick={() => this.handleSave()} disabled={isPosting || !isFormValid(anuncio, isEdit)} />
            </Col>
          </Row>
        </Container>
      </Screen>
    )
  }

  handleSave = () => {
    const { isEdit, anuncio } = this.state
    const {
      isDoador,
      dispatch,
      anuncio: { isUpdating }
    } = this.props
    console.log('anuncio', anuncio)
    // return
    if (isEdit) {
      if (isDoador) {
        dispatch(updateAnuncioDoacao(anuncio))
      } else {
        const anuncioProdutos = anuncio.anuncioProdutos.map((ap) => {
          ap.valor = ap.preco
          return ap
        })
        anuncio.anuncioProdutos = anuncioProdutos
        dispatch(updateAnuncio(anuncio))
      }
    } else {
      if (isDoador) {
        dispatch(addAnuncioDoacao(anuncio))
      } else dispatch(addAnuncio(anuncio))
    }
  }

  renderError = () => {
    const {
      anuncio: { anuncio }
    } = this.props

    return (
      <TelaSucesso>
        <Mensagem>Anúncio {this.state.isEdit ? 'editado' : 'registrado'} com sucesso!</Mensagem>
        {!this.state.isEdit && <Link to={URL_VENDA_ANUNCIO_EDITAR(anuncio.id)}>Editar esse anuncio</Link>} <br />
        <Link className='my-2' to={URL_VENDA_ANUNCIO_LISTAR()}>
          Listar anuncios
        </Link>
        <br />
        <Link to='/'>Tela Principal</Link>
      </TelaSucesso>
    )
  }

  render () {
    const {
      connection,
      dispatch,
      isLoading,
      contrato,
      anuncio: { anuncio, isPosting, hasError, hasSuccess, error },
      clearError
    } = this.props
    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoading || isPosting) {
      return <Loading />
    }

    if (hasError) {
      return <TelaDeErro error={error} callbackReturn={() => dispatch(clearError())} message='Erro ao tentar cadastrar anuncio' />
    }

    if (anuncio && hasSuccess) {
      return this.renderError()
    }

    if (isObjectReady(contrato) && isObjectReady(this.props.anuncio)) {
      console.log('isFormValid', isFormValid(anuncio))
      return this.renderAnuncioForm()
    }
    return <Loading />
  }
}

const mapStateToProps = (state) => {
  const { doacao } = state.perfil
  const { doador, donatario } = doacao
  const isDoacao = doador || donatario
  return {
    connection: state.main.connection,
    contrato: isDoacao ? state.doacaoContrato.contrato : state.vendaContrato.contrato,
    isLoading: state.vendaContrato.isLoading || state.doacaoContrato.isLoading,
    produtos: state.produto.produtos,
    anuncio: isDoacao ? state.doacaoAnuncio : state.vendaAnuncio,
    isDoador: isDoacao
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clearError,
  updateAnuncio,
  addAnuncio,
  loadProdutos,
  loadContratoById
})

export default connect(mapStateToProps, mapDispatchToProps)(AnuncioNovo)
