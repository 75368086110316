import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { loadVitrine } from 'store/reserva/action'
import { loadReservas } from 'store/reserva-doacao/action'
import { DisplayStringLimit, DisplayString } from 'util/display'
import Loading from 'components/loading'
import Refresh from 'components/refresh'
import { TableList, ListHeaders, ListHeaderTitle, ListBody, ListBodyRow, ListBodyCol, ActionButton, EmptyList } from 'components/style/tableList'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Screen } from 'components/style'

function isDoacao () {
  return window.location.href.toLowerCase().includes('doacao')
}

class ReservaList extends React.Component {
  componentDidMount () {
    const { dispatch } = this.props
    if (isDoacao()) dispatch(loadReservas())
    else dispatch(loadVitrine())
  }

  renderBodyRow = (anuncio) => (
    <ListBodyRow key={anuncio.id}>
      <ListBodyCol first format='text'>
        {anuncio.contrato.nome}
      </ListBodyCol>
      <ListBodyCol format='datetime'>{anuncio.dataInicioReserva}</ListBodyCol>
      <ListBodyCol format='datetime'>{anuncio.dataFimReserva}</ListBodyCol>
      <ListBodyCol format='datetime'>{anuncio.dataInicioEntrega}</ListBodyCol>
      <ListBodyCol format='datetime'>{anuncio.dataFimEntrega}</ListBodyCol>
      <ListBodyCol last format='actionButtons'>
        {anuncio.reservas.length == 0 ? (
          <ActionButton to={`/venda/reserva/novo/${anuncio.id}`} icon={faPen} />
        ) : (
          <ActionButton to={`/venda/reserva/editar/${anuncio.reservas[0].id}?isEdit=true`} icon={faPen} />
        )}
      </ListBodyCol>
    </ListBodyRow>
  )

  renderListReservaList = (list) => (
    <TableList>
      <ListHeaders>
        <ListHeaderTitle>Reserva</ListHeaderTitle>
        {/* <ListHeaderTitle>Empresa</ListHeaderTitle> */}
        <ListHeaderTitle className='mobile'>Inicio Reserva</ListHeaderTitle>
        <ListHeaderTitle className='mobile'>Fim Reserva</ListHeaderTitle>
        <ListHeaderTitle className='mobile'>Inicio Entrega</ListHeaderTitle>
        <ListHeaderTitle className='mobile'>Fim Entrega</ListHeaderTitle>
      </ListHeaders>
      <ListBody>{list.map((anuncio) => this.renderBodyRow(anuncio))}</ListBody>
    </TableList>
  )

  render () {
    const { list, isLoading, connection, dispatch } = this.props

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoading === true) {
      return <Loading />
    } else if (list !== undefined && list.constructor === Array && list.length > 0) {
      return (
        <Screen back={{ to: '/', title: 'Menu' }}>
          <Container fluid>{this.renderListReservaList(list)}</Container>
        </Screen>
      )
    }

    return (
      <Screen back={{ to: '/', title: 'Menu' }}>
        <EmptyList />
      </Screen>
    )
  }
}

const mapStateToProps = (state) => ({
  list: isDoacao() ? state.doacaoReserva.list : state.vendaVitrine.list,
  isLoading: isDoacao() ? state.doacaoReserva.isLoading : state.vendaVitrine.isLoading,
  connection: state.main.connection
})
const mapDispatchToProps = (dispatch) => ({ dispatch, DisplayStringLimit, DisplayString })
export default connect(mapStateToProps, mapDispatchToProps)(ReservaList)
