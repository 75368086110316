import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'

import ACTIONS from 'store/perfil/reducerTypes'
import { Screen } from 'components/style'
import { isUpcycling } from 'util/utils'
import { push } from 'connected-react-router'
function SelecaoPerfil ({ dispatch }) {
  useEffect(() => {
    if (window.location.pathname !== '/') {
      dispatch(push('/'))
    }
  })
  return (
    <Screen header={false} footer={false} middle>
      <Row>
        <Col xs={12}>
          <img id='saveadd-main-logo' src={`${process.env.PUBLIC_URL}/${isUpcycling() ? 'logoUpcycling.svg' : 'logo.svg'}`} alt='' style={{ maxWidth: '400px' }} />
        </Col>
        <Col xs={6}>
          <Button className='w-100 btn-selecao-perfil' style={{ minHeight: '120px' }} onClick={() => dispatch({ type: ACTIONS.PERFIL.SELECIONAR.DOACAO.DONATARIO })}>
            Donatário
          </Button>
        </Col>
        <Col xs={6}>
          <Button className='w-100 btn-selecao-perfil' style={{ minHeight: '120px' }} onClick={() => dispatch({ type: ACTIONS.PERFIL.SELECIONAR.DOACAO.DOADOR })}>
            Doador
          </Button>
        </Col>
        <Col xs={6}>
          <Button className='w-100 btn-selecao-perfil' style={{ minHeight: '120px' }} onClick={() => dispatch({ type: ACTIONS.PERFIL.SELECIONAR.VENDA.COMPRADOR })}>
            Comprador
          </Button>
        </Col>
        <Col xs={6}>
          <Button className='w-100 btn-selecao-perfil' style={{ minHeight: '120px' }} onClick={() => dispatch({ type: ACTIONS.PERFIL.SELECIONAR.VENDA.VENDEDOR })}>
            Vendedor
          </Button>
        </Col>
      </Row>
    </Screen>
  )
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  dispatch
})
export default connect(mapStateToProps, mapDispatchToProps)(SelecaoPerfil)
