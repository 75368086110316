import { requestApi } from 'util/utils'
import { push } from 'connected-react-router'
import { API_BASE } from 'settings'
import ACTIONS from './reducerTypes'
import { URL_PRODUTO_LOAD, URL_PRODUTO_POST, URL_ESTOQUE_POST } from './urls'

export const editEstoque = (produto) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.ESTOQUE.LOTE.POST.BEGIN })
    return requestApi(dispatch, URL_PRODUTO_POST, 'POST', produto)
      .fetch()
      .then((result) => {
        if (result && result.data) {
          return dispatch({
            type: ACTIONS.ESTOQUE.LOTE.POST.SUCCESS
            // payload: result.data
          })
        }
        return dispatch({ type: ACTIONS.PRODUTO.ESTOQUE.FAILURE })
      })
  }
}
export const loadProdutos = (perfil) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.PRODUTO.LOAD.BEGIN })
    return requestApi(dispatch, URL_PRODUTO_LOAD)
      .fetch()
      .then(async (result) => {
        if (result && result.data) {
          const entregas = result.data.itens
          const payload = entregas.map((entrega) => {
            entrega.entregaProduto = {
              ...entrega.entregaProduto,
              ...entrega.entregaProduto.produto,
              estoque: entrega.quantidadeAtual,
              id: entrega.id
            }
            return entrega.entregaProduto
          })

          const obj = {
            itens: payload
          }
          console.log(obj)
          return dispatch({
            type: ACTIONS.PRODUTO.LOAD.SUCCESS,
            payload: obj
          })
        }
        return dispatch({ type: ACTIONS.PRODUTO.LOAD.FAILURE })
      })
  }
}
export const postProduto = (data) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.PRODUTO.POST.BEGIN })
    return requestApi(dispatch, URL_PRODUTO_POST, 'POST', data)
      .fetch()
      .then((result) => {
        if (result && result.data) {
          dispatch({ type: ACTIONS.PRODUTO.POST.SUCCESS, payload: result.data })
          return dispatch(push(`/produto/sucesso/${result && result.data && result.data.idProduto}/editar`))
        }
        return dispatch({ type: ACTIONS.PRODUTO.POST.FAILURE })
      })
  }
}
