import React, { useState } from 'react'
import Steps from 'components/estoque/catalogo/steps'
import { useHistory } from 'react-router-dom'
import { Screen } from 'components/style'
import { connect } from 'react-redux'
import { sendProduct } from 'store/importar-produtos-lote/actions'

const { Step2, Step3, Step4, Step5, Step6, Step7 } = Steps

function EditarProduto ({ dispatch, isEditing }) {
  const [position, setPosition] = useState(1)
  const [produto, setProduto] = useState({
    idProduto: undefined,
    codigoInterno: undefined,
    nomeProduto: undefined,
    codigoBarras: undefined,
    descricao: undefined,
    precoBase: 0,
    unidadeBase: 'KG'
  })

  const positions = [!produto.codigoInterno, !produto.nomeProduto, !produto.codigoBarras, !produto.descricao, produto.precoBase <= 0, !produto.unidadeBase]

  const history = useHistory()
  const isFormInvalid = positions.some((validation) => validation)

  function next (payload) {
    setProduto((prevProduto) => ({ ...prevProduto, ...payload }))
    setPosition((prevPosition) => prevPosition + 1)
  }

  function back (payload) {
    setProduto((prevProduto) => ({ ...prevProduto, ...payload }))
    setPosition((prevPosition) => prevPosition - 1)
  }

  async function finish (payload) {
    const prod = { ...produto, ...payload }
    setProduto(prod)
    if (isFormInvalid) {
      const index = positions.findIndex((validation) => validation)
      setPosition(index + 1)
      return
    }
    await dispatch(sendProduct(produto.idInterno, produto))
    history.push('/produtos')
  }
  function onChange (field, value) {
    setProduto({ ...produto, [field]: value })
  }
  function CurrentStep () {
    switch (position) {
      case 1:
        return <Step2 next={next} back={back} produto={produto} onChange={onChange} />
      case 2:
        return <Step3 next={next} back={back} produto={produto} onChange={onChange} />
      case 3:
        return <Step4 next={next} back={back} produto={produto} onChange={onChange} />
      case 4:
        return <Step5 next={next} back={back} produto={produto} onChange={onChange} />
      case 5:
        return <Step6 next={next} back={back} produto={produto} onChange={onChange} />
      case 6:
        return <Step7 finish={finish} back={back} produto={produto} onChange={onChange} />
      default:
        return <></>
    }
  }

  function Positions () {
    return positions.map((isInvalid, i) => {
      let classes = 'button-circle'
      if (isInvalid) {
        classes += ' button-circleRed'
      }
      if (i !== 4) {
        classes += ' mr-1 mr-sm-3'
      }
      return (
        <div onClick={() => setPosition(i + 1)} key={i} style={{ border: '2px solid #eee', cursor: 'pointer' }} className={classes} variant='outlined'>
          <div>{i + 1}</div>
        </div>
      )
    })
  }

  return (
    <Screen back={{ to: '/produtos', title: 'Menu' }}>
      <div style={{ overflowX: 'hidden' }}>
        <div className='d-flex justify-content-center flex-wrap mt-4'>
          <Positions />
        </div>
        <div className='mt-4 mx-3'>
          <CurrentStep />
        </div>
      </div>
    </Screen>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(EditarProduto)
