import React from 'react'
import { Screen } from 'components/style'
import { Row, Container } from 'react-bootstrap'
import Input from 'components/input'

const renderFieldNome = () => (
  <Input
    autoFocus
    type='text'
    name='nome'
    id='signupNome'
    //    value={this.state.nome}
    //    onChange={(e) => this.handleChangeInput(e)}
    // onBlur={validation}
    errorClass='invalid'
    label='Nome'
    autoComplete='off'
    maxLength={255}
    minLength={1}
    ref={(e) => e}
  />
)

const renderFieldCpf = () => (
  <Input
    type='tel'
    mask='111.111.111-11'
    name='cpf'
    id='signupCpf'
    autoComplete='off'
    //    value={this.state.cpf}
    //    onChange={(e) => this.handleChangeInput(e)}
    // onBlur={validation}
    label='CPF'
    errorClass='invalid'
  />
)

const renderFieldEmail = () => (
  <Input
    type='text'
    name='email'
    id='signupEmail'
    //    onChange={(e) => this.handleChangeInput(e)}
    // onBlur={validation}
    //    value={this.state.email}
    errorClass='invalid'
    label='E-mail'
    autoComplete='off'
  />
)

const renderFieldNascimento = () => (
  <Input
    type='date'
    name='dataNascimento'
    id='signupDataNascimento'
    //    value={this.state.dataNascimento}
    //    onChange={(e) => this.handleChangeInput(e)}
    // onBlur={validation}
    errorClass='invalid'
    label='Data de nascimento'
    autoComplete='off'
  />
)

const renderFieldSenha = () => (
  <Input
    type='password'
    name='senha'
    data-test='signupSenha'
    id='signupSenha'
    //    value={this.state.senha}
    //    onChange={(e) => this.handleChangeInput(e)}
    errorClass='invalid'
    autoComplete='off'
    // onBlur={validation}
    label='Senha'
  />
)

const renderFieldSenhaConfirmar = () => (
  <Input
    type='password'
    name='confirmarSenha'
    data-test='signupConfirmarSenha'
    id='signupConfirmarSenha'
    //    value={this.state.confirmarSenha}
    //    onChange={(e) => this.handleChangeInput(e)}
    errorClass='invalid'
    autoComplete='off'
    label='Confirmar senha'
    // onBlur={validation}
  />
)

const renderDadosUsuario = () => (
  <form>
    <input type='text' style={{ width: 0.1, height: 0.1, opacity: 0.01, margin: 0, zIndex: -99999 }} />
    <h5 className='card-title'>Dados da pessoa física</h5>
    <div className='row'>
      <div className='col-md-8'>{renderFieldNome()}</div>
      <div className='col-md-4'>{renderFieldCpf()}</div>
    </div>
    <div className='row'>
      <div className='col-md-8'>{renderFieldEmail()}</div>
      <div className='col-md-4'>{renderFieldNascimento()}</div>
    </div>
    <div className='row'>
      <div className='col-md-6'>{renderFieldSenha()}</div>
      <div className='col-md-6'>{renderFieldSenhaConfirmar()}</div>
    </div>
    <div className='row mt-5'>
      <div className='col-sm-12 d-flex justify-content-end'>
        <div className='form-group'>
          <button type='button' className='btn btn-danger btn-back-login'>
            Castrar
          </button>
        </div>
      </div>
    </div>
  </form>
)

export default function CadastroUsuarioNovo () {
  return (
    <Screen back={{ to: '/', title: 'Menu' }}>
      <Container>{renderDadosUsuario()}</Container>
    </Screen>
  )
}
