import React from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import { Row, Col, Alert, Card, Container, Accordion, Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import dayjs from 'dayjs'
import {
  loadReservaById,
  loadDetalhes,
  saveReserva,
  resetarEtapa,
  mountReserva,
  iWontCollect,
  verifyMyOwnReservas,
  saveFile,
  clearError,
  finishReserva
} from 'store/reserva/action'
import {
  saveReserva as saveReservaDoacao,
  editReserva as editReservaDoacao,
  iWontCollect as iWontCollectDoacao,
  verifyMyOwnReservas as verifyMyOwnReservasDoacao,
  loadReservaById as loadReservaByIdDoacao
} from 'store/reserva-doacao/action'

import { TelaSucesso, Link, Mensagem } from 'components/telaSucesso'
import TelaDeErro from 'components/telaDeErro'
import ObservationTextArea from 'components/observationTextArea'
import Loading from 'components/loading'
import Refresh from 'components/refresh'
import BotaoSalvar from 'components/BotaoSalvar'
import { formasPagamento } from 'util/pagamentos'
import { Screen } from 'components/style'
import { isNotNullOrEmpty, isNullOrEmpty, orderProductsAsc } from 'util/utils'
import { DisplayString } from 'util/display'
import { toastr } from 'react-redux-toastr'
import { loadAnuncioById } from 'store/anuncio/action'
import { loadAnuncioById as loadAnuncioByIdDoacao } from 'store/anuncio-doacao/action'
import { URL_VENDA_ANUNCIO_LISTAR } from 'store/anuncio/urls'
import MyCustomToggle from 'components/reserva/MyCustomToggle'
import BoxProduto from 'components/reserva/boxProduto'
import 'styles/vendaReserva.css'
import Dropzone from 'components/Dropzone'
const PIX = '2AF5FC90-50CE-4319-87B5-3C4FD92A9E43'
const TRANSFERENCIA = 'A1E8DA6B-659D-4069-811A-12B4AD644D3A'
const ListarProdutos = ({ produtos, onChange, isSale }) => {
  console.log()
  if (!produtos || produtos.length === 0) return <div className='text-center'>Não há produtos disponíveis para essa reserva.</div>
  return produtos.map((produto) => <BoxProduto key={produto.id} {...produto} isSale={isSale} quantidade={produto.quantidade} onChange={onChange} />)
}
class VendaReservaEditar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      reservaProdutos: [],
      anuncio: [],
      vendaRecorrente: {},
      idVendaRecorrente: '',
      idVendaRecorrenteReserva: '',
      idVendaRecorrenteAnuncio: '',
      loaded: false,
      formasDePagamentoContrato: [],
      observacaoReserva: '',
      observacaoNegativa: '',
      showModal: false,
      arquivoCarregado: {},
      isEdit: false,
      sendLater: false,
      isSale: false
    }
  }

  async componentDidMount () {
    const {
      match: { params },
      dispatch,
      isDoador
    } = this.props
    const idAnuncio = params.id
    const isEdit = this.props.location.search.includes('isEdit')
    this.setState({
      isEdit,
      isSale: !isDoador
    })
    if (isEdit) await this.loadReserva(idAnuncio)
    await this.loadDetalhesReserva(params.id)
    if (isDoador) dispatch(verifyMyOwnReservasDoacao())
    else dispatch(verifyMyOwnReservas())
  }

  async loadReserva (idAnuncio) {
    const { dispatch, loadAnuncioById, loadReservaByIdDoacao, isDoador } = this.props
    const loadReserva = isDoador ? loadReservaByIdDoacao : loadReservaById
    const { payload } = await loadReserva(idAnuncio)()
    console.log('payload', payload)
    this.setState({ reservaProdutos: payload.ultimaInteracao.reservaProdutos })
    await dispatch(loadAnuncioById(payload.idAnuncio)).then((result) => {
      const { contrato } = result.payload
      const formasDePagamentoReserva = contrato.formasDePagamento.map((pagamento) => {
        const index = formasPagamento.findIndex((pagamentoContant) => pagamentoContant.id.toUpperCase() === pagamento.idFormaPagamento.toUpperCase())
        return {
          ...pagamento,
          nome: formasPagamento[index].nome
        }
      })
      this.setState({ formasDePagamentoContrato: formasDePagamentoReserva })
    })
    setTimeout(() => {
      this.handleSelectedPayment(payload.ultimaInteracao.idFormaDePagamento)
    })
  }

  async loadDetalhesReserva (idAnuncioVenda) {
    const { dispatch, loadAnuncioById, loadAnuncioByIdDoacao, isDoador } = this.props
    const loadAnuncio = isDoador ? loadAnuncioByIdDoacao : loadAnuncioById
    const { payload } = await dispatch(loadAnuncio(idAnuncioVenda))
    const { contrato, anuncioProdutos } = payload
    var formasDePagamentoContrato
    if (!isDoador) formasDePagamentoContrato = await this.getFormasDePagamento(contrato)
    const produtos = anuncioProdutos.map((produto) => {
      produto.quantidadeDisponivel = produto.quantidade
      produto.quantidade = 0
      const prod = produto.produto
      delete produto.produto
      return { ...prod, ...produto }
    })
    this.setState({ ...payload, reservaProdutos: produtos, formasDePagamentoContrato })
  }

  getFormasDePagamento = (contrato) =>
    contrato.formasDePagamento.map((pagamento) => {
      const index = formasPagamento.findIndex((pagamentoContant) => pagamentoContant.id.toUpperCase() === pagamento.idFormaPagamento.toUpperCase())
      return {
        ...pagamento,
        nome: formasPagamento[index].nome
      }
    })

  getProdutos = (produtos, anuncio) => {
    const prods = anuncio.anuncioProdutos.map(({ idProduto, nomeProduto, quantidadeDevolucao, quantidadePerda, unidade, produto, idAnuncioProduto }) => {
      const entity = produtos.find((p) => p.idProduto === idProduto)
      if (entity) {
        return {
          idProduto,
          nomeProduto,
          quantidade: quantidadeDevolucao + quantidadePerda,
          quantidadeReservada: quantidadeDevolucao + quantidadePerda,
          unidade,
          produto,
          idAnuncioProduto
        }
      } else return entity
    })

    return produtos
      .map((produto) => {
        const item = anuncio.anuncioProdutos.find((x) => x.idProduto === produto.idProduto)
        if (item !== undefined) {
          return {
            quantidadeReservada: 0,
            ...produto,
            quantidade: item.quantidadeDevolucao + item.quantidadePerda,
            unidade: item.unidade,
            idAnuncioProduto: item.idAnuncioProduto,
            nomeProduto: item.nomeProduto
          }
        }

        return {
          quantidadeReservada: 0,
          ...produto,
          quantidade: 0,
          unidade: ''
        }
      })
      .concat(prods)
  }

  componentDidUpdate () {
    const entity = this.props.vendaReserva
    if (this.state.loaded && !entity) {
      const reservaProdutos = this.getProdutos(entity.reservaProdutos, entity.anuncio).filter((p) => p.quantidade > 0)
      const newState = {
        loaded: true,
        anuncio: entity.anuncio,
        vendaRecorrente: entity.anuncio.vendaRecorrente,
        reservaProdutos,
        idVendaRecorrente: entity.idVendaRecorrente,
        idVendaRecorrenteReserva: entity.idVendaRecorrenteReserva,
        idVendaRecorrenteAnuncio: entity.idVendaRecorrenteAnuncio,
        observacaoReserva: entity.observacaoReserva || ''
      }
      this.setState(newState)
    }
  }

  valorTotal () {
    let total = 0
    this.state.reservaProdutos
      .filter((p) => p.quantidade > 0)
      .forEach((p) => {
        total += p.quantidade * p.valor
      })
    return total
  }

  createObjectToApi () {
    const { formasDePagamento, observacaoReserva, reservaProdutos, isSale, isEdit } = this.state
    let id
    id = this.props.match.params.id
    return {
      idReserva: null,
      id,
      reserva: {
        idAnuncio: this.state.isEdit ? id : this.props.anuncio.id || id
      },
      status: 'confirmado',
      idFormaDePagamento: isSale ? formasDePagamento.idFormaPagamento : undefined,
      observacao: observacaoReserva,
      reservaProdutos: reservaProdutos
        .map((produto) => ({
          ...produto,
          idAnuncioProduto: produto.id,
          valor: produto.precoUnitario // || produto.valor,
        }))
        .filter((produto) => produto.quantidade > 0)
    }
  }

  renderList (item) {
    console.log(item)
    if (item === undefined || !item.contrato) return null
    let nome = ''
    if (item.contrato !== undefined && item.contrato !== null) nome = DisplayString(item.contrato.nome)

    return (
      <div className='table-responsive'>
        <table className='table table-striped table-hover table-sm mb-3'>
          <thead>
            <tr>
              <th scope='col'>Empresa</th>
              <th scope='col'>Inicio Reserva</th>
              <th scope='col'>Fim Reserva</th>
            </tr>
          </thead>
          <tbody>
            <tr key={item.idVendaRecorrenteAnuncio}>
              <td>
                <span>{`${item.empresa.nomeEmpresa}`}</span>
              </td>
              <td>{dayjs(this.state.dataInicioReserva).format('DD/MM/YYYY - HH[h]mm')}</td>
              <td>{dayjs(this.state.dataFimReserva).format('DD/MM/YYYY - HH[h]mm')}</td>
            </tr>
          </tbody>
        </table>
        <Accordion defaultActiveKey='0'>
          <Card style={{ width: '100%' }}>
            <MyCustomToggle as={Card.Header} eventKey='0'>
              Contrato: {nome}
            </MyCustomToggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <Container>
                  <Row>
                    <Col xs={12} sm={12} md={8}>
                      <span>
                        <b>Descrição do contrato: </b>- {`${item.contrato.descricao}`}
                      </span>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    )
  }

  handleOnChangeQuantidade = (idProduto, quantidade, precoTotal, precoUnitario, quantidadeDisponivel) => {
    const index = this.state.reservaProdutos.findIndex((produto) => produto.idProduto === idProduto)
    if (index === -1) return
    const reservaProdutos = this.state.reservaProdutos
    reservaProdutos[index].quantidade = quantidade
    reservaProdutos[index].valor = precoTotal
    reservaProdutos[index].precoUnitario = precoUnitario
    reservaProdutos[index].quantidadeDisponivel = quantidadeDisponivel
    this.setState({ reservaProdutos })
  }

  renderSelectPayments = () => {
    const pagamentos = this.state.formasDePagamentoContrato
    const pagamentosJaSelecionados = this.state.formasDePagamento
    if (pagamentos !== undefined && pagamentos.constructor === Array && pagamentos.length > 0) {
      const filteredPagamentos =
        pagamentosJaSelecionados !== undefined && pagamentosJaSelecionados.constructor === Array && pagamentosJaSelecionados.length > 0
          ? pagamentos.filter((pagamento) => !pagamentosJaSelecionados.some((pg) => pg.idFormaPagamento === pagamento.idFormaPagamento))
          : pagamentos

      if (filteredPagamentos && filteredPagamentos.length > 0) {
        const optionsPagamentos = orderProductsAsc(filteredPagamentos).map((item) => {
          return { value: item.idFormaPagamento, label: item.nome }
        })

        return (
          <Select
            id='pagamento'
            value='0'
            name='pagamento'
            placeholder='Selecione o pagamento...'
            options={optionsPagamentos}
            searchable
            onChange={(e) => this.handleSelectedPayment(e.value)}
            noOptionsMessage={() => 'Pagamento não existe'}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 })
            }}
          />
        )
      }
    }

    return <Select id='pagamento' value='0' name='pagamento' placeholder='Selecione o pagamento...' options={[{ value: '0', label: '-' }]} searchable isDisabled />
  }

  handleSelectedPayment (idPagamento) {
    const pagamentosReserva = this.state.formasDePagamentoContrato
    const index = pagamentosReserva.findIndex((pagamento) => pagamento.idFormaPagamento === idPagamento)
    this.setState({ formasDePagamento: pagamentosReserva[index] })
  }

  renderCurrentPagamentos = () => {
    const { formasDePagamento } = this.state

    if (!formasDePagamento || !formasDePagamento.idFormaPagamento) {
      return <div className='form-control border-0'>Não há forma de pagamento selecionada.</div>
    }

    const { idFormaPagamento, dados, banco, codigoBanco, agencia, conta } = formasDePagamento

    if (idFormaPagamento.toUpperCase() === PIX && isNotNullOrEmpty(dados)) {
      const { tipoDeChave, chave } = JSON.parse(dados)
      return (
        <div>
          <h3>PIX</h3>
          <strong>Tipo chave:</strong> {tipoDeChave}
          <br />
          <strong>Chave:</strong> {chave}
        </div>
      )
    }

    if (idFormaPagamento.toUpperCase() === TRANSFERENCIA && isNotNullOrEmpty(dados)) {
      return (
        <div>
          <h3>Transfência Bancária</h3>
          <strong>Banco:</strong> {banco || ''} ({codigoBanco || ''})<br />
          <strong>Agência:</strong> {agencia || ''}
          <br />
          <strong>Conta:</strong> {conta || ''}
        </div>
      )
    }

    return (
      <div className='form-group'>
        <label>Pagamento selecionado:</label>
        <br />
        {formasDePagamento.nome}
      </div>
    )
  }
  saveWithoutCollect () {
    const { dispatch, isDoador } = this.props
    const { anuncio } = this.state

    const obj = {
      idVendaRecorrenteAnuncio: anuncio.idVendaRecorrenteAnuncio,
      idVendaRecorrente: anuncio.idVendaRecorrente,
      observacaoReserva: this.state.observacaoNegativa
    }
    if (isDoador) dispatch(iWontCollectDoacao(obj))
    else dispatch(iWontCollect(obj))
  }

  renderAlert () {
    const { isErrorInApi } = this.props
    if (isErrorInApi === true) return <Alert>Houve um erro ao salvar! Verifique todos os campos e tente novamente.</Alert>
  }

  withoutCollect () {
    return (
      <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Não irá entregar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form>
              <Row className='show-grid'>
                <Col xs={12}>
                  <ObservationTextArea
                    onChange={(e) => this.setState({ observacaoNegativa: e.target.value })}
                    ref={this.textAreaRef}
                    maxchars={500}
                    labeltextarea='Informe-nos o motivo.'
                    textarearows='3'
                    value={this.state.observacaoNegativa}
                  />
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => this.setState({ showModal: false })}>
            Cancelar
          </Button>
          <button
            className='btn btn-primary'
            onClick={() => {
              this.saveWithoutCollect()
              this.setState({ showModal: false })
            }}
          >
            Adicionar
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  etapa1 () {
    const { dispatch, isDoador, saveReserva, editReserva, saveReservaDoacao, editReservaDoacao, isLoadingBtn, anuncio, mountReserva } = this.props
    const { reservaProdutos, isSale } = this.state
    const precoTotal = reservaProdutos.reduce((acc, cur) => acc + cur.valor, 0)
    function toBrl (value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    }
    function shareInfo () {
      const reserva = anuncio.anuncio
      let textoFinal = `Dados do Anuncio: \n\nReserva: ${reserva.contrato.nome} \n`
      const textoReserva = `Reserva inicia em: ${dayjs(reserva.dataInicioReserva).format('DD/MM/YY')} e termina em ${dayjs(reserva.dataFimReserva).format('DD/MM/YY [as] H:mm')} \n`
      const textoEntrega = `Entrega inicia em ${dayjs(reserva.dataInicioEntrega).format('DD/MM/YY')} e termina em ${dayjs(reserva.dataFimEntrega).format(
        'DD/MM/YY [as] H:mm'
      )} \n\n`
      textoFinal += textoReserva + textoEntrega + 'Produtos: \n'
      reservaProdutos.forEach((produto) => {
        let textoProduto = ''
        const textoPreco = !isDoador ? `${toBrl(produto.precoUnitario)}/${produto.unidade}` : ''
        if (produto.quantidade) textoProduto = `${produto.quantidade}${produto.unidade} ${textoPreco}`
        else textoProduto = isDoador ? `${produto.quantidadeDisponivel}${produto.unidade} Disponíveis` : `R$${produto.precoUnitario.toFixed(2)}/${produto.unidade}`
        textoFinal += `${produto.nomeProduto} - ${textoProduto} \n`
      })
      const shareUrl = `${window.location.origin}/${isDoador ? 'doacao' : 'venda'}/reserva/novo/${reserva.id} `
      textoFinal += `Para acessar, utilize o seguinte link: ${shareUrl}`
      navigator.clipboard.writeText(textoFinal)
      toastr.success('Texto copiado')
    }
    const selectAll = () => {
      const newReservaProdutos = reservaProdutos.map((produto) => {
        produto.quantidade = produto.quantidadeDisponivel
        return produto
      })
      this.setState({ reservaProdutos: newReservaProdutos })
    }
    const onSubmit = () => {
      const reserva = this.createObjectToApi()
      this.setState({ reservaProdutos: reserva.reservaProdutos })
      if (isDoador) {
        if (this.state.isEdit) dispatch(editReservaDoacao(reserva))
        else dispatch(saveReservaDoacao(reserva))
      } else {
        if (this.state.isEdit) dispatch(editReserva(reserva))
        else {
          dispatch(mountReserva())
        }
      }
    }
    const activeProducts = reservaProdutos.filter((produto) => produto.quantidadeDisponivel > (produto.reserva || produto.reservado || 0))
    return (
      <>
        {this.withoutCollect()}
        <div className='container'>
          <div className='mt-4 mb-4'>
            {this.renderAlert()}
            {this.renderList(this.state)}
            <div className='lastObservations'>{this.state.anuncio.observacaoAnuncio && <div>{`Observação do anúncio: ${this.state.anuncio.observacaoAnuncio}`}</div>}</div>
            {!!activeProducts.length && (
              <div className='d-flex justify-content-end mb-3'>
                <Button
                  onClick={() => {
                    selectAll()
                  }}
                  variant='success'
                >
                  Selecionar Todos Produtos
                </Button>
              </div>
            )}
            <ListarProdutos produtos={activeProducts} onChange={this.handleOnChangeQuantidade} isSale={isSale} />
            <br />

            {!isDoador && (
              <div className='text-right pr-4'>
                <div>
                  <b>Quantidade total reservada:</b> {reservaProdutos.reduce((acc, cur) => acc + cur.quantidade, 0)}
                </div>
                <div>
                  <b>Valor total:</b> {precoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </div>
              </div>
            )}
            <br />
            <div>
              {isSale && (
                <div className='form-group'>
                  <label htmlFor='produto'>Adicionar Pagamento: *</label>
                  {this.renderSelectPayments()}
                </div>
              )}
              {isSale && this.renderCurrentPagamentos()}
            </div>
            <ObservationTextArea
              onChange={(e) => this.setState({ observacaoReserva: e.target.value })}
              ref={this.textAreaRef}
              maxchars={500}
              labeltextarea='Observações:'
              textarearows='3'
              value={this.state.observacaoReserva}
            />
          </div>
          <label>* Itens obrigatórios</label>
          <div className='row justify-content-end my-2'>
            <div className='col-md-3'>
              <Button variant='secondary' onClick={() => shareInfo()} className='w-100'>
                Compartilhar
              </Button>
            </div>
            <div className='col-md-3  '>
              <BotaoSalvar onClick={() => onSubmit()} disabled={isLoadingBtn || (this.state.formasDePagamento === undefined && isSale)} />
            </div>
          </div>
        </div>
      </>
    )
  }

  etapa2 () {
    const {
      arquivoCarregado: { type, content, nome }
    } = this.state
    const { dispatch, saveReserva, finishReserva } = this.props
    const isDisabled = this.state.sendLater ? false : isNullOrEmpty(nome)
    const submitReseva = () => {
      const reserva = this.createObjectToApi()
      this.setState({ reservaProdutos: reserva.reservaProdutos })
      reserva.enviarDepois = this.state.sendLater
      setTimeout(async () => {
        const result = await dispatch(saveReserva(reserva))
        console.log(result)
        if (this.state.sendLater) return dispatch(finishReserva())
        else {
          dispatch(
            saveFile({
              FileName: result.id + nome && nome.match(/\.[0-9a-z]+$/i)[0],
              ContentType: type,
              Data64: content
            })
          )
        }
      })
    }
    return (
      <>
        <div className='container'>
          <div className='mt-4 mb-4'>
            <h3 style={{ textAlign: 'center' }}>Ao finalizar, compartilha conosco o comprovante para agilizarmos o processo de liberação</h3>
            <br />
            <Card>
              <Card.Body>
                <Card.Text style={{ background: '#00a99d', padding: '8px', color: '#FFF', fontSize: '1.5em' }}>
                  Total<span className='float-right'>R$ {new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(this.valorTotal())}</span>
                </Card.Text>
              </Card.Body>
            </Card>
            {this.renderCurrentPagamentos()}
            <div className='d-flex align-items-center my-3'>
              <Switch className='mr-2' onChange={(e) => this.setState({ sendLater: e })} uncheckedIcon={false} checkedIcon={false} checked={this.state.sendLater || false} />
              <div>Enviarei depois</div>
            </div>
            <Dropzone
              callback={(nome, type, content) => {
                console.log('arquivoCarregado', nome, type, content)
                this.setState({ arquivoCarregado: { nome, type, content } })
              }}
            />
            <BotaoSalvar
              text='Enviar reserva'
              disabled={isDisabled}
              onClick={() => {
                submitReseva()
              }}
            />
          </div>
        </div>
      </>
    )
  }

  etapa3 () {
    return (
      <>
        <Mensagem>Reserva salva com sucesso!</Mensagem>
        <Link to={URL_VENDA_ANUNCIO_LISTAR()}>Voltar para lista de reservas</Link>
        <br />
        <Link to='/'>Tela Principal</Link>
      </>
    )
  }

  render () {
    const { isLoading, connection, dispatch, isLoadingBtn, vendaReserva, isLoadingDetalhesAnuncio, isLoadingContratoVenda, etapa, hasError, error, isClient, isDoador } = this.props

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoading || isLoadingBtn || isLoadingDetalhesAnuncio || isLoadingContratoVenda) {
      return <Loading />
    }

    if (hasError) {
      console.log('chegou erro', error)
      return <TelaDeErro error={error} callbackReturn={clearError} message='Erro ao tentar cadastrar reserva' />
    }

    if (vendaReserva && vendaReserva.anuncio && vendaReserva.anuncio.semVenda) {
      return (
        <h1 className='container-success' style={{ textAlign: 'center', padding: '0 1rem' }}>
          Infelizmente não há itens disponíveis para doação hoje.
        </h1>
      )
    }
    const backurl = `${isDoador ? '/doacao' : '/venda'}/${isClient ? 'reserva' : 'anuncio'}/listar`

    if (etapa === 3) {
      return <Screen back={{ to: backurl, title: 'Anúncios' }}>{this.etapa3()}</Screen>
    }

    if (etapa === 2) {
      return <Screen back={{ to: backurl, title: 'Anúncios' }}>{this.etapa2()}</Screen>
    }

    return <Screen back={{ to: backurl, title: 'Anúncios' }}>{this.etapa1()}</Screen>
  }
}

const mapStateToProps = (state) => {
  const { doacao, venda } = state.perfil
  const { doador, donatario } = doacao
  const { vendedor, comprador } = venda
  const isDoador = doador || donatario

  return {
    connection: state.main.connection,
    anuncio: isDoador ? state.doacaoAnuncio : state.vendaAnuncio,
    isLoadingDetalhesAnuncio: isDoador ? state.doacaoAnuncio.idLoadingById : state.vendaAnuncio.isLoading,
    isDoador,
    ...state.vendaVitrine,
    isClient: doacao.donatario || venda.comprador
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadDetalhes,
  mountReserva,
  resetarEtapa,
  saveReserva,
  loadReservaByIdDoacao,
  loadAnuncioById,
  loadAnuncioByIdDoacao,
  saveReservaDoacao,
  editReservaDoacao,
  iWontCollectDoacao,
  finishReserva
})

export default connect(mapStateToProps, mapDispatchToProps)(VendaReservaEditar)
