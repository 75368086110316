
export default {
  EMPRESA: {
    LOAD: {
      BEGIN: 'EMPRESA_ONGS_LOAD_BEGIN',
      SUCCESS: 'EMPRESA_LOAD_SUCCESS',
      FAILURE: 'EMPRESA_LOAD_FAILURE'
    },
    NONGS: {
      LOAD: {
        BEGIN: 'EMPRESA_NONGS_LOAD_BEGIN',
        SUCCESS: 'EMPRESA_NONGS_LOAD_SUCCESS',
        FAILURE: 'EMPRESA_NONGS_LOAD_FAILURE'
      }
    },
    ONGS: {
      LOAD: {
        BEGIN: 'EMPRESA_ONGS_LOAD_BEGIN',
        SUCCESS: 'EMPRESA_ONGS_LOAD_SUCCESS',
        FAILURE: 'EMPRESA_ONGS_LOAD_FAILURE'
      }
    }
  },
  GRUPOS: {
    LOAD: {
      BEGIN: 'EMPRESA_GRUPOS_LOAD_BEGIN',
      SUCCESS: 'EMPRESA_GRUPOS_LOAD_SUCCESS',
      FAILURE: 'EMPRESA_GRUPOS_LOAD_FAILURE'
    }
  }
}
