import { Row, Button, FormControl } from 'react-bootstrap'
import React, { useState } from 'react'
import InputNumber from 'components/input/inputNumberConfirm'

function Step1 ({ next, nome, motivoBase, onChange }) {
  const [motivo, setMotivo] = useState(motivoBase)
  const motivos = ['Consumo - refeição', 'Consumo - transformação', 'Descarte - fora do padrão', 'Descarte - validado']
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-1'>Qual é o motivo do registro?</div>
      <div className='d-flex flex-column align-items-center'>
        {motivos.map((motivoOpcao, index) => (
          <Button style={{ width: '300px' }} key={motivoOpcao} onClick={() => setMotivo(motivoOpcao)} className='mb-2' variant={motivoOpcao === motivo ? 'dark' : 'outline-dark'}>
            {motivoOpcao}
          </Button>
        ))}
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center pl-5 ml-3'>
          <Button onClick={() => next({ motivo })} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step2 ({ nome, estoque, finish, back, onChange }) {
  const [valor, setValor] = useState(estoque)
  const max = JSON.parse(localStorage.getItem('produto')).quantidadeAtual
  function handleNext () {
    finish(valor)
  }
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-2 '>Qual é a nova quantidade?</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <InputNumber id='valor' valor={valor} min={0} max={max} precision={2} onChange={(e) => setValor(Number(e), 'valor')} />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <div className='mt-5 pt-3'>
          <Row className='justify-content-center'>
            <Button onClick={() => back({ estoque: valor })} className='mr-3' variant='outline-dark' color='primary'>
              Voltar
            </Button>
            <Button onClick={handleNext} variant='dark'>
              Finalizar
            </Button>
          </Row>
        </div>
      </div>
    </>
  )
}
export default { Step1, Step2 }
