import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import InputNumber from 'components/input/inputNumberConfirm'
import dayjs from 'dayjs'

const BoxProduto = ({ onChange, quantidade, precoBase, nomeProduto, ultimaModificacao, idProduto, unidadeBase, vencimento, quantidadeDisponivel, precoTotal, isSale }) => {
  const [produto, setProduto] = useState({
    quantidade,
    precoUnitario: precoBase || 0,
    quantidadeDisponivel,
    precoTotal: precoTotal || 0,
    isSale
  })

  useEffect(() => {
    const { quantidade, precoTotal, precoUnitario, quantidadeDisponivel } = produto
    onChange(idProduto, quantidade, precoTotal, precoUnitario, quantidadeDisponivel)
  }, [produto, idProduto, onChange])

  useEffect(() => {
    setProduto((prevProduto) => ({
      ...prevProduto,
      quantidade
    }))
  }, [quantidade])

  const handleOnChange = (e) => {
    const quantidade = parseFloat(e)
    const precoTotal = quantidade > 0 ? produto.precoUnitario * quantidade : 0
    setProduto((prevProduto) => ({
      ...prevProduto,
      quantidade,
      precoTotal,
      valor: precoTotal
    }))
  }

  const selecionarDisponiveis = () => {
    setProduto((prevProduto) => ({
      ...prevProduto,
      quantidade: prevProduto.quantidadeDisponivel
    }))
  }

  const hoje = dayjs()
  const validade = vencimento ? dayjs(vencimento) : ultimaModificacao ? dayjs(ultimaModificacao.validade) : null

  const dias = validade ? validade.diff(hoje, 'days') : 0

  return (
    <div key={`produtoDetalhes-${idProduto}`}>
      <Card style={{ width: '100%' }}>
        <Card.Header className='d-flex justify-content-between items-center'>
          <div>
            {nomeProduto} {unidadeBase}
          </div>
          <Button onClick={selecionarDisponiveis}>Selecionar Tudo</Button>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <b>Quantidade Reservada:</b>
              </Col>
              <Col>
                <InputNumber
                  id={`qtdReservado-${idProduto}`}
                  type='number'
                  valor={produto.quantidade}
                  className='form-control py-1 w-100'
                  min={0}
                  precision={3}
                  confirmacao={false}
                  autoComplete='off'
                  max={produto.quantidadeDisponivel}
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Quantidade Disponível:</b>
              </Col>
              <Col className='inputSemControles text-center'>{produto.quantidadeDisponivel.toFixed(3)}</Col>
            </Row>
            {isSale && (
              <>
                <Row>
                  <Col>
                    <b>Preço Unitário:</b>
                  </Col>
                  <Col className='inputSemControles text-center'>R$ {parseFloat(produto.precoUnitario).toFixed(2)}</Col>
                </Row>
                <Row>
                  <Col>
                    <b>Preço Total:</b>
                  </Col>
                  <Col className='inputSemControles text-center'>R$ {parseFloat(produto.precoTotal).toFixed(2)}</Col>
                </Row>
                {validade && (
                  <>
                    <Row>
                      <Col>
                        <b>Validade:</b>
                      </Col>
                      <Col className='text-center'>{validade.format('DD/MM/YYYY')}</Col>
                    </Row>
                    <Row className=' mb-2'>
                      <Col>
                        <b>Dias para vencer:</b>
                      </Col>
                      <Col className='text-center'>{dias}</Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

BoxProduto.propTypes = {
  onChange: PropTypes.func.isRequired,
  quantidade: PropTypes.number,
  precoBase: PropTypes.number,
  nomeProduto: PropTypes.string,
  idProduto: PropTypes.string,
  unidadeBase: PropTypes.string,
  reservado: PropTypes.number,
  quantidadeDisponivel: PropTypes.number,
  precoTotal: PropTypes.number,
  isSale: PropTypes.bool,
  ultimaModificacao: PropTypes.shape({
    validade: PropTypes.string
  }),
  vencimento: PropTypes.string
}

export default BoxProduto
