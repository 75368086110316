import React from 'react'
import { connect } from 'react-redux'
import Loading from 'components/loading'
import { BtnSalvar } from 'components/Botao'
import { isArrayNotEmpty } from 'util/utils'
import { CardDeck } from 'react-bootstrap'
import SearchBar from 'components/search-bar'
import { getProdutosFiltrados } from 'util/importar-produtos-lote'
import BoxProduto from 'components/produto/boxProdutoEstoque'
import { push } from 'connected-react-router'
import 'styles/produto.css'
const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 }
  ]
}

class TelaEstoque extends React.Component {
  constructor (props) {
    super(props)
    const { produtos } = this.props
    this.elementoTopo = React.createRef()

    this.state = {
      produtosComEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosAlterados: {},
      categoria: {},
      hasSearch: false,
      subCategoria: {},
      inputFileFieldRef: Math.random().toString(36),
      estagio: 0
    }

    this.displayProdutosList = this.displayProdutosList.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    const { produtos, isEstoqueLotePosting } = this.props
    console.log('produtos-redux', produtos)
    if (produtos !== prevProps.produtos) {
      this.setProdutosFiltrados()
    }

    const estagio1Concluido = prevProps.isEstoqueLotePosting && !isEstoqueLotePosting
    if (estagio1Concluido) {
      this.setState({
        estagio: 0,
        produtosComEstoque: [],
        produtosSemEstoque: [],
        produtosAlterados: {}
      })
    }
  }

  handleEdit = (produto) => {
    const { dispatch } = this.props
    localStorage.removeItem('produto')
    localStorage.setItem('produto', JSON.stringify(produto))
    setTimeout(() => {
      dispatch(push(`/produtos/edit-comprador/${produto.idProduto}`))
    }, 10)
  }

  displayProdutosList (produtos, onChange) {
    if (isArrayNotEmpty(produtos)) {
      return produtos.map((produto, i) => {
        const hasChange = this.state.produtosAlterados[produto.idProduto]
        if (hasChange) produto = { ...hasChange, ...produto }
        return (
          <BoxProduto
            key={produto.idProduto}
            produto={produto}
            i={i}
            onChange={onChange}
            onEdit={this.handleEdit}
            hide={this.state.estagio !== 0}
            hidePrice
            origin={produto.motivoAtualizacao}
          />
        )
      })
    }

    return <>Não existem produtos na categoria selecionada</>
  }

  getProdutosAlterados = () => Object.keys(this.state.produtosAlterados).map((el) => this.state.produtosAlterados[el])

  getProdutosFiltrados = (produtos) => getProdutosFiltrados(produtos, this.props.categorias, this.state.categoria, this.state.subCategoria)
  setProdutosFiltrados = () =>
    this.setState({
      produtosComEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque <= 0))
    })

  handleUpdateProduto = (dados, callback) => {
    const { produtos } = this.props
    const currentProdutosAlterados = this.state.produtosAlterados
    const produtoAlterado = produtos.filter((el) => el.idProduto === dados.idProduto || (el.codigoInterno && el.codigoInterno === dados.codigoInterno))[0]
    if (produtoAlterado) {
      currentProdutosAlterados[produtoAlterado.idProduto] = { ...produtoAlterado, ...dados }
      this.setState(
        {
          produtosAlterados: { ...currentProdutosAlterados }
        },
        callback
      )
    }
  }

  hasInvalidItens = () =>
    !Object.keys(this.state.produtosAlterados).length || Object.keys(this.state.produtosAlterados).some((x) => this.state.produtosAlterados[x].invalid === true)

  botaoSalvar = () => <BtnSalvar onSalvar={() => this.setState({ estagio: 1 })} disabled={this.hasInvalidItens()} />

  estagioAdicionarAlteracoes = (produtos) => {
    const onChangeItems = ({ result, query }) => {
      const hasSearch = query !== null && query.trim().length > 0
      console.log('set state', hasSearch)
      this.setState({ hasSearch })
    }
    return (
      <div>
        <SearchBar hasSearch={this.state.hasSearch} items={this.props.produtos} options={searchOptions} placeholder='Procurar em estoque' onSearchItems={onChangeItems} />
        <hr />
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtos, this.handleUpdateProduto)}</CardDeck>
      </div>
    )
  }

  render () {
    const { isEstoqueLotePosting, produtos } = this.props
    console.log('entrou', produtos)
    if (isEstoqueLotePosting) {
      return <Loading />
    }

    return <div ref={this.elementoTopo}>{this.estagioAdicionarAlteracoes(produtos)}</div>
  }
}

const mapStateToProps = (state) => {
  console.log('tttst', state.estoqueComprador.produtos)
  return {
    isEstoqueLotePosting: state.estoqueComprador.isEstoqueLotePosting
  }
}
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TelaEstoque)
