import React from 'react'
import PropTypes from 'prop-types'
import { Screen } from './style'

const TelaSucess = ({ children, title, to, header, className }) => (
  <Screen back={{ to, title: header }} className={`success-screen text-center ${className}`}>
    <div className='container-success'>
      <h1 className='success-title mb-5'>{title}</h1>
      {children}
      {/* <RouterLink to={to} className="success-button">
        Voltar
      </RouterLink> */}
    </div>
  </Screen>
)

TelaSucess.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  header: PropTypes.string,
  className: PropTypes.string
}

export { TelaSucess }
