import React, { useState } from 'react'
import ReactExport from 'react-export-excel'
import { handleOnFileChangeEventAsync } from 'util/importar-produtos-lote'
import { Row, Button, Modal } from 'react-bootstrap'
import dayjs from 'dayjs'

export const ExcelButtons = ({ produtosComEstoque, produtosSemEstoque, onImport, isCatalogo }) => {
  const [showEmptyFileAlert, setShowEmptyFileAlert] = useState(false)
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
  const produtos = [...produtosComEstoque, ...produtosSemEstoque]
  const prods = produtos.map(({ unidadeBase, idProduto, idEmpresa, estoque, codigoInterno, nomeProduto, precoBase, ultimaModificacao }) => {
    const numLote = ultimaModificacao ? ultimaModificacao.numLote : ''
    const vencimento = ultimaModificacao ? ultimaModificacao.validade : ''
    const vencimentoFormatado = vencimento ? dayjs(vencimento, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm') : '';
    return {
      idEmpresa,
      idProduto,
      codigoInterno,
      nomeProduto,
      quantidade: Number(estoque) || 0,
      precoBase: Number(precoBase) || 0,
      unidadeBase,
      vencimento: vencimentoFormatado,
      numLote
    }
  })
  const excelColumns = (isCatalogo) => {
    var defaultColumns = []
    if (isCatalogo) {
      defaultColumns = [
        { label: 'Código do Produto', value: 'codigoInterno' },
        { label: 'Nome do Produto', value: 'nomeProduto' },
        { label: 'Descrição do Produto', value: 'descricao' },
        { label: 'Código de barras', value: 'codigoDeBarras' },
        { label: 'Unidade Base', value: 'unidadeBase' },
        { label: 'Preço Base', value: 'precoBase' }
      ]
    } else {
      // estoque
      defaultColumns = [
        { label: 'Código do Produto', value: 'codigoInterno' },
        { label: 'Nome do Produto', value: 'nomeProduto' },
        { label: 'Quantidade', value: 'quantidade' },
        { label: 'Preço Base', value: 'precoBase' },
        { label: 'Unidade Base', value: 'unidadeBase' },
        { label: 'Validade', value: 'vencimento' },
        { label: 'Lote', value: 'numLote' }
      ]
    }
    return defaultColumns.map((column, i) => <ExcelColumn key={i} label={column.label} value={column.value} />)
  }

  const formatProdCatalogo = ({ codigoInterno, nomeProduto, codigoDeBarras, descricao, precoBase, unidadeBase, vencimento, numLote }) => ({
    codigoInterno: codigoInterno.toString(),
    nomeProduto: typeof nomeProduto === 'string' ? nomeProduto.trim() : nomeProduto,
    descricao: typeof descricao === 'string' ? descricao.trim() : '',
    codigoDeBarras,
    precoBase: Number(precoBase) || 0,
    unidadeBase,
    vencimento,
    numLote
  })

  const importarPlanilhaCallBack = (rowsPlanilha, isCatalogo) => {
    console.log('importarPlanilhaCallBack', rowsPlanilha, isCatalogo)
    if (!(rowsPlanilha && rowsPlanilha.length)) {
      setShowEmptyFileAlert(true)
      return
    } else {
      setShowEmptyFileAlert(false)
      onImport(rowsPlanilha, isCatalogo)
    }

    try {
      if (isCatalogo) {
        const prods = rowsPlanilha.map((produto) => formatProdCatalogo(produto))
        onImport(prods, isCatalogo)
      } else {
        const formatProd = (row) => {
          if (!row || !row.nomeProduto) {
            return {}
          }

          return {
            codigoInterno: row.codigoInterno.toString(),
            nomeProduto: row.nomeProduto.trim(),
            quantidade: Number(row.quantidade) || Number(row.estoque) || 0,
            precoBase: Number(row.precoBase) || 0,
            unidadeBase: row.unidadeBase,
            vencimento: row.vencimento || undefined,
            numLote: row.numLote || undefined
          }
        }

        const alteracoesComEstoque = rowsPlanilha.filter((row) => {
          const produtoFormatado = formatProd(row)
          const produtoExistente = produtosComEstoque.find((produto) => produto.codigoInterno === produtoFormatado.codigoInterno)
          if (!produtoExistente) return false
          return Object.entries(produtoFormatado).some(([key, value]) => produtoExistente[key] !== value)
        })

        const alteracoesSemEstoque = rowsPlanilha.filter((row) => {
          const produtoFormatado = formatProd(row)
          const produtoExistente = produtosSemEstoque.find((produto) => produto.codigoInterno === produtoFormatado.codigoInterno)
          if (!produtoExistente) return false
          return Object.entries(produtoFormatado).some(([key, value]) => produtoExistente[key] !== value)
        })

        const formatedAlteracoesComEstoque = alteracoesComEstoque.map((produto) => {
          const [prod] = produtosComEstoque.filter((prod) => prod.codigoInterno === produto.codigoInterno)
          return {
            ...prod,
            ...produto
          }
        })

        const formatedAlteracoesSemEstoque = alteracoesSemEstoque.map((produto) => {
          const [prod] = produtosSemEstoque.filter((prod) => prod.codigoInterno === produto.codigoInterno)
          return {
            ...prod,
            ...produto
          }
        })

        const totalLength = alteracoesComEstoque.length + alteracoesSemEstoque.length

        const payload = { alteracoesComEstoque: formatedAlteracoesComEstoque, alteracoesSemEstoque: formatedAlteracoesSemEstoque, quantidadeAlteracoes: totalLength }

        onImport(payload)
      }
    } catch (error) {
      console.error('Ocorreu um erro ao importar a planilha:', error)
      return error
    }
  }

  return (
    <Row className='float-right mb-3 '>
      <label htmlFor={`file-upload${isCatalogo ? 1 : 0}`} className='btn saveadd-primary-color'>
        Enviar Planilha
      </label>
      <input
        type='file'
        id={`file-upload${isCatalogo ? 1 : 0}`}
        name={`file-upload${isCatalogo ? 1 : 0}`}
        onChange={async (event) => {
          event.persist()
          try {
            await handleOnFileChangeEventAsync(event, (e) => {
                importarPlanilhaCallBack(e, isCatalogo)
              }, isCatalogo)
            event.target.value = null
          } catch (error) {
            return error
          }
        }}
      />
      {isCatalogo ? (
        <ExcelFile element={<button className='btn btn-primary'>Baixar planilha de Catálogo</button>} filename='Planilha de Inclusão de Produtos'>
          <ExcelSheet data={[]} name='PlanilhaPadrão'>
            {excelColumns(true)}
          </ExcelSheet>
        </ExcelFile>
      ) : (
        <ExcelFile element={<button className='btn btn-primary'>Baixar planilha de Estoque</button>} filename='Planilha Atualização de Produtos'>
          <ExcelSheet data={prods} name='PlanilhaPadrão'>
            {excelColumns(false)}
          </ExcelSheet>
        </ExcelFile>
      )}

      <Modal show={showEmptyFileAlert} onHide={() => setShowEmptyFileAlert(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body>A planilha está vazia. Por favor, selecione outra planilha válida.</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowEmptyFileAlert(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default ExcelButtons
