import { getDate } from 'util/date'
import lod from 'lodash'
import dayjs from 'dayjs'

const isAfterToday = (data = new Date()) => getDate(data) >= getDate()
const isBefore = (inicio, fim) => dayjs(inicio) < (dayjs(fim))

export const isReservaDataInicioValid = (anuncio, isEdit) => isEdit ? true : isAfterToday(anuncio.dataInicioReserva)

export const isReservaDataFimValid = (anuncio, isEdit) => {
  let validInitialDate = true
  if (!isEdit) validInitialDate = isAfterToday(anuncio.dataFimReserva)

  return validInitialDate &&
    isBefore(anuncio.dataInicioReserva, anuncio.dataFimReserva)
}

export const isEntregaDataInicioValid = (anuncio) => isBefore(anuncio.dataInicioReserva, anuncio.dataInicioEntrega)

export const isEntregaDataFimValid = (anuncio, isEdit) => {
  let validInitialDate = true
  if (!isEdit) validInitialDate = isAfterToday(anuncio.dataFimReserva)
  return validInitialDate &&
    isBefore(anuncio.dataFimReserva, anuncio.dataFimEntrega)
}
export const isFormValid = (anuncio, isEdit) => {
  if (!anuncio) return false
  // const anuncioRaw = { ...anuncio, anuncioProdutosRemovidos: null }
  console.log('valid', isReservaDataInicioValid(anuncio, isEdit))
  return (
    // !lod.isEqual(original, anuncioRaw) &&
    isReservaDataInicioValid(anuncio, isEdit) &&
    isEntregaDataInicioValid(anuncio, isEdit) &&
    isReservaDataFimValid(anuncio, isEdit) &&
    isEntregaDataFimValid(anuncio, isEdit) &&
    (anuncio.anuncioProdutos.some(ap => ap.quantidade > 0) || anuncio.isEstoqueProduto)
  )
}
