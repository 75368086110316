import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { connectRouter } from 'connected-react-router'
import estoqueCompradorReducer from 'store/estoque-comprador/reducers'
import loginReducer from 'store/login/loginReducer'
import empresaReducer from 'store/empresa/reducer'

import perfilReducer from 'store/perfil/reducers'

import { VendaAnuncioReducer, VendaEntregaAnuncianteReducer, VendaEntregaCompradorReducer } from 'modules/negocios/venda/reducers'
import { DoacaoAnuncioReducer, DoacaoReservaReducer, DoacaoEntregaDoadorReducer, DoacaoEntregaDonatarioReducer } from 'modules/negocios/doacao/reducers'
import VendaReservaReducer from 'store/reserva/reducer'
import DoacaoContratoReducer from 'store/contrato-doacao/reducer'
import VendaContratoReducer from 'store/contrato/reducers'
import signupReducer from 'store/signup/reducers'
import produtoReducer from 'store/produto/reducers'
import globalReducer from './mainReducer'
import importarProdutos from 'store/importar-produtos-lote/reducers'

export default (history) =>
  combineReducers({
    main: globalReducer,
    login: loginReducer,
    cadastro: signupReducer,
    // Reducers libs
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    empresa: empresaReducer,
    perfil: perfilReducer,

    // Reducers Venda
    vendaContrato: VendaContratoReducer,
    vendaAnuncio: VendaAnuncioReducer,
    vendaVitrine: VendaReservaReducer,
    vendaEntregaComprador: VendaEntregaCompradorReducer,
    vendaEntregaAnunciante: VendaEntregaAnuncianteReducer,

    // Estoque Comprador
    estoqueComprador: estoqueCompradorReducer,

    // Reducers Doacao
    doacaoContrato: DoacaoContratoReducer,
    doacaoAnuncio: DoacaoAnuncioReducer,
    doacaoReserva: DoacaoReservaReducer,
    doacaoEntregaDonatario: DoacaoEntregaDonatarioReducer,
    doacaoEntregaDoador: DoacaoEntregaDoadorReducer,

    // Reducers produtos
    importarProdutos: importarProdutos,
    produto: produtoReducer
  })
