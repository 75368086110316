import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUndo } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Row, Col, Container } from 'react-bootstrap'
import InputNumber from 'components/input/inputNumberConfirm'
import dayjs from 'dayjs'

function ProdutoItem (props) {
  const { itemProduto, isEstoqueProduto, isPrecoProduto, onChange, onRemove, onRestore, removido, isSale, isRegistered } = props
  const vencimento = itemProduto.produto.vencimento ? dayjs(itemProduto.produto.vencimento) : undefined
  const daysUntilExpire = vencimento ? dayjs(vencimento).diff(dayjs(), 'days') : undefined

  useEffect(() => {
    console.log('useEffect', itemProduto)
  }, [itemProduto])
  const handleOnChange = (payload) => {
    onChange({ ...itemProduto, ...payload })
  }

  const handleOnRemove = (idProduto) => onRemove(idProduto)

  const handleOnRestore = (idProduto) => onRestore(idProduto)

  const displayOpenItem = () => {
    const optionsUnidades = ['CX', 'KG', 'UN', 'SACO'].map((item) => ({
      value: item,
      label: item
    }))

    const { unidade, quantidade, preco, idProduto, produto } = itemProduto

    const precoBase = isPrecoProduto ? Number(produto.precoBase) : parseFloat(preco, 10)
    const qtdBase = isEstoqueProduto ? parseFloat(produto.estoque) : parseFloat(quantidade, 10)
    const qtd = qtdBase > 0 ? qtdBase : 0
    const precoValue = precoBase > 0 ? precoBase : 0
    const precoTotal = precoValue * qtd
    const desabilitado = removido === true
    const unidadeValue = unidade || itemProduto.produto.unidadeBase
    console.log('qtd', qtd, 'precoValue', precoValue, 'precoTotal', precoTotal, 'preco', preco)
    return (
      <Container className='inside-table table-opened' key={`tb_produto_item_${idProduto}`}>
        <Row>
          <Col>
            Unidade: <br />
            <Select
              isSearchable={false}
              id={`unidade_${idProduto}`}
              key={`unidade_${idProduto}`}
              name='unidade'
              placeholder='Unidade'
              options={optionsUnidades}
              onChange={(e) => handleOnChange({ unidade: e.value })}
              value={{ value: unidadeValue, label: unidadeValue }}
              isDisabled={desabilitado}
              className='styled-select-unidade'
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 })
              }}
            />
          </Col>
          <Col>
            Quantidade: <br />
            <InputNumber
              type='number'
              id={`quantidade_${idProduto}`}
              className='form-control py-1 w-100 nospin '
              valor={qtd}
              min={0}
              precision={3}
              autoComplete='off'
              readOnly={desabilitado}
              onChange={(e) => handleOnChange({ quantidade: Number(e) })}
              disabled={isEstoqueProduto}
            />
          </Col>
          {isSale && (
            <>
              <Col>
                Preço: <br />
                <InputNumber
                  type='number'
                  id={`preco_${idProduto}`}
                  className='form-control py-1 w-100 nospin '
                  valor={precoValue}
                  min={0}
                  mobile
                  precision={2}
                  readOnly={desabilitado}
                  onChange={(e) => handleOnChange({ preco: Number(e) })}
                  disabled={isPrecoProduto}
                />
              </Col>
              <Col>
                Preço total: <br />
                <InputNumber
                  type='number'
                  id={`precoTotal_${idProduto}`}
                  valor={parseFloat(precoTotal, 10)}
                  className='form-control py-1 w-100 nospin precoTotal'
                  min={0}
                  noStyle
                  readOnly
                  precision={2}
                />
              </Col>
            </>
          )}
        </Row>
        {daysUntilExpire !== undefined && (
          <div className='d-flex flex-column flex-sm-row mt-1'>
            <div className='mr-sm-3'>
              <b>Vencimento: </b>
              {dayjs(vencimento).format('DD/MM/YYYY')}
            </div>
            <div>
              <b>Dias para vencer: </b>
              {daysUntilExpire >= 0 ? (daysUntilExpire === 0 ? 'Hoje' : daysUntilExpire) : 'Vencido'}
            </div>
          </div>
        )}
      </Container>
    )
  }

  const renderBotaoRemover = () => (
    <Button variant='light' className='float-right' onClick={() => handleOnRemove(itemProduto.idProduto)}>
      <FontAwesomeIcon icon={faTimes} color='#FF0000' size='sm' />
    </Button>
  )

  const renderBotaoRestaurar = () => (
    <Button variant='light' className='float-right' onClick={() => handleOnRestore(itemProduto.idProduto)}>
      <FontAwesomeIcon icon={faUndo} color='#00FF00' size='sm' />
    </Button>
  )

  const { unidade, quantidade } = itemProduto
  let bg = isRegistered ? 'saveadd-primary-color' : 'bg-warning'
  bg = window.location.href.includes('edit') ? bg : 'saveadd-primary-color'
  const qtd = parseFloat(quantidade !== undefined ? quantidade : 0)
  const unidadeValue = unidade || itemProduto.produto.unidadeBase

  return (
    <>
      <Card>
        <Card.Header style={{ justifyContent: 'space-between' }} className={'w-100 h-100 d-flex align-items-center ' + bg}>
          <strong>
            {itemProduto.produto.nomeProduto} <small>{` (${qtd} ${unidadeValue})`}</small>
          </strong>
          {removido ? renderBotaoRestaurar() : renderBotaoRemover()}
        </Card.Header>
        <Card.Body className='h-100' style={{ marginTop: '10px' }}>
          {displayOpenItem()}
        </Card.Body>
      </Card>
    </>
  )
}

ProdutoItem.propTypes = {
  itemProduto: PropTypes.shape({
    idProduto: PropTypes.string.isRequired,
    unidade: PropTypes.string,
    produto: PropTypes.shape({
      nomeProduto: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isEstoqueProduto: PropTypes.bool.isRequired,
  isPrecoProduto: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onRestore: PropTypes.func,
  removido: PropTypes.bool,
  isSale: PropTypes.bool,
  isRegistered: PropTypes.bool
}

const mapStateToProps = (state) => ({
  method: state.vendaAnuncio.method
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoItem)
