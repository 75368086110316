import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Switch from 'react-switch'

import Alignment from 'components/Alignment'

const EstoqueSwitchComPreco = ({ isEstoque, isPreco, onChangeEstoque, onChangePreco, isSale }) => (
  <Row>
    <Col xs={12} md={isSale ? 6 : 12}>
      <Alignment vMiddle as='label'>
        Estoque: Anuncio&nbsp;&nbsp;
        <Switch onChange={() => onChangeEstoque(!isEstoque)} uncheckedIcon={false} checkedIcon={false} checked={isEstoque || false} />
        &nbsp;&nbsp; Produto
      </Alignment>
    </Col>
    {isSale && (
      <Col xs={12} md={6}>
        <Alignment vMiddle as='label'>
          Preço: Anuncio&nbsp;&nbsp;
          <Switch onChange={() => onChangePreco(!isPreco)} uncheckedIcon={false} checkedIcon={false} checked={isPreco || false} />
          &nbsp;&nbsp; Produto
        </Alignment>
      </Col>
    )}
  </Row>
)

EstoqueSwitchComPreco.propTypes = {
  isEstoque: PropTypes.bool.isRequired,
  isPreco: PropTypes.bool.isRequired,
  onChangeEstoque: PropTypes.func.isRequired,
  onChangePreco: PropTypes.func.isRequired,
  isSale: PropTypes.bool
}

export default EstoqueSwitchComPreco
