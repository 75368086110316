export default {
  ETAPA: {
    RESET: 'ETAPA_RESET'
  },
  RESERVA: {
    CLEARERROR: 'VENDA_RESERVA_CLEARERROR',
    LOAD: {
      ALL: {
        BEGIN: 'VENDA_RESERVA_LOAD_BEGIN',
        SUCCESS: 'VENDA_RESERVA_LOAD_SUCCESS',
        FAILURE: 'VENDA_RESERVA_LOAD_FAIL'
      },
      DETAILS: {
        BEGIN: 'VENDA_RESERVA_DETALHES_BEGIN',
        SUCCESS: 'VENDA_RESERVA_DETALHES_SUCCESS',
        FAILURE: 'VENDA_RESERVA_DETALHES_FAIL'
      }
    },
    SAVE: {
      RESERVA: {
        BEGIN: 'VENDA_RESERVA_SAVE_BEGIN',
        MOUNT: 'VENDA_RESERVA_SAVE_MOUNT',
        SUCCESS: 'VENDA_RESERVA_SAVE_SUCCESS',
        FAILURE: 'VENDA_RESERVA_SAVE_FAILURE'
      },
      COMPROVANTE: {
        BEGIN: 'VENDA_FILE_SAVE_BEGIN',
        SUCCESS: 'VENDA_FILE_SAVE_SUCCESS',
        FAILURE: 'VENDA_FILE_SAVE_FAILURE'
      }
    },
    DESISTIU: {
      BEGIN: 'VENDA_RESERVA_DESISTIU_BEGIN',
      SUCCESS: 'VENDA_RESERVA_DESISTIU_SUCCESS',
      FAILURE: 'VENDA_RESERVA_DESISTIU_FAILURE'
    }
  }
}
