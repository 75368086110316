import React from 'react'
import URL from '../../modules/negocios/venda/urls-browser'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row } from 'react-bootstrap'
import { faEye, faEyeSlash, faSpinner, faPen, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { DisplayStringLimit } from 'util/display'
import Loading from 'components/loading'
import Refresh from 'components/refresh'
import { ativarContrato, inativarContrato, loadContratoTodos } from 'store/contrato/action'
import { ativarContrato as ativarContratoDoacao, inativarContrato as inativarContratoDoacao, loadContratoTodos as loadContratoTodosDoacao } from 'store/contrato-doacao/action'
import { TableList, ListHeaders, ListHeaderTitle, ListBody, ListBodyRow, ListBodyCol } from 'components/style/tableList'
import { Screen } from 'components/style'

class VendaContratoListar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      tipo: ''
    }
  }

  componentDidMount () {
    const {
      loadContratoTodos,
      loadContratoTodosDoacao,
      isDoador,
      match: { params }
    } = this.props
    if (isDoador) loadContratoTodosDoacao()
    else loadContratoTodos()
    if (params) {
      this.setState({ tipo: params.tipo })
    }
  }

  renderStatus (contrato) {
    const { ativarContrato, inativarContrato, isDoador } = this.props
    function handleAtivarContrato (id) {
      if (isDoador) ativarContratoDoacao(id)
      else ativarContrato(id)
    }
    function handleInativarContrato () {
      if (isDoador) inativarContratoDoacao(contrato.id)
      else inativarContrato(contrato.id)
    }
    if (contrato.statusUpdating !== undefined && contrato.statusUpdating === true) {
      return (
        <Link to='#' className='btn-danger btn-lg btncenter'>
          <FontAwesomeIcon icon={faSpinner} size='1x' />
        </Link>
      )
    } else if (contrato.ativo === true) {
      return (
        <Link onClick={() => handleInativarContrato(contrato.id)} to='#' className='btn-danger btn-lg btncenter'>
          <FontAwesomeIcon icon={faEyeSlash} size='1x' />
        </Link>
      )
    } else {
      return (
        <Link onClick={() => handleAtivarContrato(contrato.id)} to='#' className='btn-primary btn-lg btncenter'>
          <FontAwesomeIcon icon={faEye} size='1x' />
        </Link>
      )
    }
  }

  renderBotaoCadastro = () => (
    <Link to={URL.CONTRATO.NOVO()} className='btn btn-success btn-s ml-2'>
      Novo
    </Link>
  )

  renderEditar = (line) => (
    <Link to={`${URL.CONTRATO.EDITAR(line.id)}`} className='btn-primary btn-lg btncenter'>
      <FontAwesomeIcon icon={faPen} size='1x' />
    </Link>
  )

  renderNovoAnuncio = (line) => (
    <Link to={`/venda/anuncio/novo/${line.id}`} className='btn-secondary btn-lg mobile'>
      Novo Anúncio
    </Link>
  )

  renderNovoAnuncioMobile = (line) => (
    <Link to={`/venda/anuncio/novo/${line.id}`} className='btn-secondary btn-lg botao2 button2'>
      <FontAwesomeIcon icon={faBullhorn} size='1x' />
    </Link>
  )

  renderBodyRow (contrato) {
    const { isDoador } = this.props
    const classes = isDoador ? 'vw-95' : 'vw-100'
    const nome = contrato.nome
    return (
      <tr className={`d-flex overflow-hidden ${classes}`} key={contrato.id}>
        <ListBodyCol className='col py-3 overflow-hidden text-truncate' first format='text'>
          {nome}
        </ListBodyCol>
        <ListBodyCol last format='actionButtons' className='col-auto d-flex no-wrap flex-nowrap justify-content-end py-3'>
          <span>{this.renderStatus(contrato)}</span>
          <span>{this.renderEditar(contrato)}</span>
          <span>{contrato.ativo === true && this.renderNovoAnuncio(contrato)}</span>
          <span>{contrato.ativo === true && this.renderNovoAnuncioMobile(contrato)}</span>
        </ListBodyCol>
      </tr>
    )
  }

  displayBotoesStatus = (rowNewStyle) => (
    <div className='d-flex justify-content-end'>
      <div className='col-auto mt-3 mb-3' style={rowNewStyle}>
        <Link to={URL.CONTRATO.LISTAR('todos')} className='btn-secondary btn-lg mobile' onClick={() => this.setState({ tipo: '' })}>
          Todos
        </Link>
        &nbsp;
        <Link to={URL.CONTRATO.LISTAR('ativos')} className='btn-secondary btn-lg mobile' onClick={() => this.setState({ tipo: 'ativos' })}>
          Ativos
        </Link>
        &nbsp;
        <Link to={URL.CONTRATO.LISTAR('inativos')} className='btn-secondary btn-lg mobile' onClick={() => this.setState({ tipo: 'inativos' })}>
          Inativos
        </Link>
      </div>
    </div>
  )

  renderList (list) {
    const rowNewStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    return (
      <Screen back={{ to: '/', title: 'Menu' }}>
        <div className='d-flex justify-content-end'>
          <div className='col-auto mt-3 mb-3' style={rowNewStyle}>
            <p className='mt-2 mb-2 mr-1'>Cadastrar novo Contrato:</p>
            {this.renderBotaoCadastro()}
          </div>
        </div>
        {this.displayBotoesStatus(rowNewStyle)}
        <TableList>
          <ListHeaders>
            <ListHeaderTitle>Contrato</ListHeaderTitle>
          </ListHeaders>
          <ListBody>{list.map((item) => this.renderBodyRow(item))}</ListBody>
        </TableList>
      </Screen>
    )
  }

  render () {
    const { contratosAll, contratosAtivos, contratosInativos, isLoadingAll, connection, dispatch } = this.props
    const { tipo } = this.state

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (tipo === 'ativos') {
      if (contratosAtivos !== undefined && contratosAtivos.constructor === Array && contratosAtivos.length > 0) {
        return this.renderList(contratosAtivos)
      }
    } else if (tipo === 'inativos') {
      if (contratosInativos !== undefined && contratosInativos.constructor === Array && contratosInativos.length > 0) {
        return this.renderList(contratosInativos)
      }
    } else {
      if (isLoadingAll === true) {
        return <Loading />
      }
      if (contratosAll !== undefined && contratosAll.constructor === Array && contratosAll.length > 0) {
        return this.renderList(contratosAll)
      }
    }

    const rowNewStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    return (
      <Screen back={{ to: '/', title: 'Menu' }}>
        <Row className='justify-content-end'>
          <div className='col-auto mt-3 mb-3' style={rowNewStyle}>
            <p className='mt-2 mb-2 mr-1'>Cadastre uma nova recorrência: </p>
            {this.renderBotaoCadastro()}
          </div>
        </Row>
        {this.displayBotoesStatus()}
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  const { doacao } = state.perfil
  const { doador, donatario } = doacao
  const isDoacao = doador || donatario
  return {
    contratosAll: isDoacao ? state.doacaoContrato.contratosAll : state.vendaContrato.contratosAll,
    contratosAtivos: isDoacao ? state.doacaoContrato.contratosAtivos : state.vendaContrato.contratosAtivos,
    contratosInativos: isDoacao ? state.doacaoContrato.contratosInativos : state.vendaContrato.contratosInativos,
    isLoadingAll: isDoacao ? state.doacaoContrato.isLoadingAll : state.vendaContrato.isLoadingAll,
    connection: state.main.connection,
    isDoador: isDoacao
  }
}
const mapDispatchToProps = { DisplayStringLimit, ativarContrato, inativarContrato, loadContratoTodos, loadContratoTodosDoacao, ativarContratoDoacao, inativarContratoDoacao }

export default connect(mapStateToProps, mapDispatchToProps)(VendaContratoListar)
