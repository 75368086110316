export const INICIAR_IMPORTACAO_LOTE = 'INICIAR_IMPORTACAO_LOTE'
export const CLEAR_ALL_PRODUCTS = 'CLEAR_ALL_PRODUCTS'
export const ADICIONAR_NOVO_PRODUTO = 'ADICIONAR_NOVO_PRODUTO'
export const IMPORTAR_PRODUTOS_LOTE = 'IMPORTAR_PRODUTOS_LOTE'
export const IMPORTAR_TODOS_PRODUTOS = 'IMPORTAR_TODOS_PRODUTOS'
export const LOAD_CATEGORIAS_BEGIN = 'LOAD_CATEGORIAS_BEGIN'
export const ENVIAR_PRODUTO_BEGIN = 'ENVIAR_PRODUTO_BEGIN'
export const ENVIAR_PRODUTO_SUCCESS = 'ENVIAR_PRODUTO_SUCCESS'
export const ENVIAR_PRODUTO_FAIL = 'ENVIAR_PRODUTO_FAIL'
export const DELETE_PRODUTO = 'DELETE_PRODUTO'
export const ADD_PRODUTO = 'ADD_PRODUTO'
