import React from 'react'
import { connect } from 'react-redux'
import { addMovimentoEstoqueLote } from 'store/produto/actions'
import Loading from 'components/loading'
import { BtnSalvar, BtnSalvarVoltar } from 'components/Botao'
import { isArrayNotEmpty } from 'util/utils'
import { Container, CardDeck } from 'react-bootstrap'
import SearchBar from 'components/search-bar'
import { getProdutosFiltrados } from 'util/importar-produtos-lote'
import BoxProduto from 'components/produto/boxProdutoEstoque'
import { push } from 'connected-react-router'
import { ExcelButtons } from 'components/estoque/ExcelButtons'
import dayjs from 'dayjs'
import 'styles/produto.css'

const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 }
  ]
}

class TelaEstoque extends React.Component {
  constructor (props) {
    super(props)
    const { produtos } = this.props
    this.elementoTopo = React.createRef()

    this.state = {
      produtosComEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosComEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosAlterados: {},
      categoria: {},
      hasSearch: false,
      subCategoria: {},
      inputFileFieldRef: Math.random().toString(36),
      estagio: 0,
      expireDays: 0,
      hasChanges: true
    }

    this.displayProdutosList = this.displayProdutosList.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    const { produtos, isEstoqueLotePosting } = this.props
    if (produtos !== prevProps.produtos) {
      this.setProdutosFiltrados()
    }

    const estagio1Concluido = prevProps.isEstoqueLotePosting && !isEstoqueLotePosting
    if (estagio1Concluido) {
      this.setState({
        estagio: 0,
        produtosComEstoque: [],
        produtosSemEstoque: [],
        produtosAlterados: {}
      })
    }
  }

  handleEdit = (produto) => {
    const { dispatch } = this.props
    localStorage.removeItem('produto')
    localStorage.setItem('produto', JSON.stringify(produto))
    setTimeout(() => {
      dispatch(push(`/produtos/edit/${produto.idProduto}`))
    }, 10)
  }

  displayProdutosList (produtos, onChange, listIdentifier) {
    if (isArrayNotEmpty(produtos)) {
      return produtos.map((produto, i) => {
        const hasChange = this.state.produtosAlterados[produto.idProduto]
        if (hasChange) {
          produto = { ...hasChange, ...produto }
        }
        const uniqueKey = `${produto.idProduto}_${listIdentifier}`
        return <BoxProduto key={uniqueKey} produto={produto} i={i} onChange={onChange} onEdit={this.handleEdit} hide={this.state.estagio !== 0} />
      })
    } else {
      return <>Não existem produtos na categoria selecionada</>
    }
  }

  getProdutosFiltrados = (produtos) => getProdutosFiltrados(produtos, this.props.categorias, this.state.categoria, this.state.subCategoria)

  setProdutosFiltrados = () =>
    this.setState({
      produtosComEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque <= 0))
    })

  handleUpdateProduto = (dados, callback) => {
    const { produtos } = this.props
    const currentProdutosAlterados = this.state.produtosAlterados
    const produtoAlterado = produtos.filter((el) => el.idProduto === dados.idProduto || (el.codigoInterno && el.codigoInterno === dados.codigoInterno))[0]
    if (produtoAlterado) {
      currentProdutosAlterados[produtoAlterado.idProduto] = { ...produtoAlterado, ...dados }
      this.setState(
        {
          produtosAlterados: { ...currentProdutosAlterados }
        },
        callback
      )
    }
  }

  hasInvalidItens = () =>
    !Object.keys(this.state.produtosAlterados).length || Object.keys(this.state.produtosAlterados).some((x) => this.state.produtosAlterados[x].invalid === true)

  botaoSalvar = () => <BtnSalvar onSalvar={() => this.setState({ estagio: 1 })} disabled={this.hasInvalidItens()} />

  estagioAdicionarAlteracoes = () => {
    const { produtosComEstoqueFiltrados, produtosSemEstoqueFiltrados, expireDays } = this.state
    const { isCatalogo } = this.props
    const changeItems = ({ result, query }) => {
      this.setState({
        hasSearch: !!query,
        produtosComEstoque: result.filter((x) => x.estoque > 0),
        produtosComEstoqueFiltrados: result.filter((x) => x.estoque > 0),
        produtosSemEstoque: result.filter((x) => x.estoque <= 0),
        produtosSemEstoqueFiltrados: result.filter((x) => x.estoque <= 0)
      })
    }

    const onImport = ({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes }) => {
      try {
        if (quantidadeAlteracoes > 0) {
          this.setState({
            estagio: 1,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: true
          })
        } else {
          this.setState({
            estagio: 1,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: false
          })
        }
      } catch (error) {
        console.error('Ocorreu um erro:', error)
      }
    }
    const ButtonsExcel = ({ produtosComEstoque, produtosSemEstoque }) => {
      const importar = ({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes }) => {
        onImport({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes })
      }
      return <ExcelButtons produtosComEstoque={produtosComEstoque} produtosSemEstoque={produtosSemEstoque} onImport={importar} isCatalogo={false} />
    }
    const filtrarProduto = (event) => {
      const value = Number(event.target.value)
      this.setState({ expireDays: value })
      if (value) {
        const filtroEstoque = (produto) => {
          const data = dayjs(produto.vencimento)
          const dataAtual = dayjs()
          const diferencaDias = data.diff(dataAtual, 'day')
          return value > 0 ? diferencaDias > value : diferencaDias < value * -1
        }

        const produtosEstoque = this.state.produtosComEstoqueFiltrados.filter(filtroEstoque)
        const produtosZerados = this.state.produtosSemEstoqueFiltrados.filter(filtroEstoque)

        this.setState({
          produtosComEstoqueFiltrados: produtosEstoque,
          produtosSemEstoqueFiltrados: produtosZerados
        })
      } else {
        this.setState({
          produtosComEstoqueFiltrados: this.state.produtosComEstoque,
          produtosSemEstoqueFiltrados: this.state.produtosSemEstoque
        })
      }
    }

    const Filter = () => {
      return (
        <div className='d-flex align-items-center mt-3 pl-1'>
          <div>Vence em:</div>
          <div className='ml-2'>
            <select onChange={filtrarProduto} className='form-control' value={expireDays}>
              <option value='0'>Sem Limite</option>
              <option value='7'>Mais de 7 dias</option>
              <option value='15'>Mais de 15 dias</option>
              <option value='30'>Mais de 30 dias</option>
              <option value='60'>Mais de 60 dias</option>
              <option value='-7'>Menos de 7 dias</option>
              <option value='-15'>Menos de 15 dias</option>
              <option value='-30'>Menos de 30 dias</option>
              <option value='-60'>Menos de 60 dias</option>
            </select>
          </div>
        </div>
      )
    }
    return (
      <div>
        <ButtonsExcel produtosComEstoque={produtosComEstoqueFiltrados} produtosSemEstoque={produtosSemEstoqueFiltrados} isCatalogo={isCatalogo} />
        <SearchBar hasSearch={this.state.hasSearch} items={this.props.produtos} options={searchOptions} placeholder='Procurar em estoque' onSearchItems={changeItems} />
        <Filter />
        <hr />
        <h6 className='mb-3'>Produtos com Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosComEstoqueFiltrados, this.handleUpdateProduto, 'comEstoque')}</CardDeck>
        <hr />
        <h6 className='mb-3'>Produtos sem Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosSemEstoqueFiltrados, this.handleUpdateProduto, 'semEstoque')}</CardDeck>
      </div>
    )
  }

  estagioValidarAlteracoes = () => {
    const { dispatch } = this.props
    const { produtosAlterados } = this.state
    const payload = produtosAlterados.map((produto) => {
      return {
        unidade: produto.unidadeBase,
        preco: produto.precoBase,
        idProduto: produto.idProduto,
        idLote: produto.idLote,
        motivo: 'teste',
        qrCode: produto.qrCode,
        numLote: produto.numLote,
        validade: produto.vencimento ? dayjs(produto.vencimento).format('YYYY-MM-DDTHH:mm') : undefined,
        tipo: 'substituir',
        quantidade: produto.quantidade,
        nomeProduto: produto.nomeProduto
      }
    })

    return (
      <Container>
        <h5>Produtos Alterados</h5>
        <h6>Verifique se as alterações estão corretas antes de salvar</h6>
        <hr />
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosAlterados, this.handleUpdateProduto, 'alterados')}</CardDeck>
        <BtnSalvarVoltar onVoltar={() => this.setState({ estagio: 0 })} onSalvar={() => dispatch(addMovimentoEstoqueLote(payload, 'teste'))} disabled={this.hasInvalidItens()} />
      </Container>
    )
  }

  render () {
    const { isEstoqueLotePosting } = this.props

    if (isEstoqueLotePosting) {
      return <Loading />
    }

    return (
      <div ref={this.elementoTopo}>
        {this.state.estagio === 0 ? this.estagioAdicionarAlteracoes() : null}
        {this.state.estagio === 1 ? this.estagioValidarAlteracoes() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isEstoqueLotePosting: state.produto.isEstoqueLotePosting
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TelaEstoque)
