import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Alert, Container, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Switch from 'react-switch'
import ProdutosList from 'components/anuncio/ProdutosList'
import InputNumber from 'components/input/inputNumberConfirm'
import ObservationTextArea from 'components/observationTextArea'
import Loading from 'components/loading'
import Refresh from 'components/refresh'
import { loadProdutos } from 'store/produto/actions'

import { saveReserva } from './action'
import { loadReservaById } from 'store/reserva/action'

import { isNullOrEmpty } from 'util/utils'
import { formasPagamento } from 'util/pagamentos'

import BotaoCancelar from 'components/BotaoCancelar'
import BotaoSalvar from 'components/BotaoSalvar'

import RenderInformacoes from './compoments/renderInformacoes'
import { Screen } from 'components/style'
import SectionHandler from 'components/contrato/sectionHandler'

import './entrega.css'

import Dropzone from 'components/Dropzone'
class EntregaAnuncianteEditar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      reservaProdutos: [],
      contrato: {},
      idReserva: '',
      naoLocalizado: false,
      responsavelEntrega: '',
      notafiscalEntrega: '',
      formasDePagamento: [],
      observacaoEntrega: '',
      isActive: true,
      ranking: '',
      idReservaInteracao: '',
      formaDePagamentoEntregaNome: '',
      formaDePagamentoEntregaId: '',
      reserva: {},
      isLoadingDetalheEntrega: true,
      produtosAdicionais: [],
      checked: false,
      fotos: []
    }
  }

  componentDidMount () {
    const {
      match: { params },
      dispatch,
      loadReservaById
    } = this.props
    dispatch(loadProdutos())
    const idReservaInteracao = params.id
    dispatch(loadReservaById(idReservaInteracao, { begin: 'a', success: 'b', failure: 'c' })).then((result) => {
      if (result && result.payload && result.payload.interacoes && result.payload.interacoes.length > 0) {
        this.handleLoadReservaResult(result)
      } else {
        console.log('não foi localizado a reserva para essa entrega')
        this.setState({ naoLocalizado: true, isLoadingDetalheEntrega: false })
      }
    })
  }

  handleLoadReservaResult (result) {
    const reservaProdutos = result.payload.ultimaInteracao.reservaProdutos
    const entregasRealizadas = result.payload.ultimaInteracao.entregas
    const newReservaProdutos = reservaProdutos.map((produto) => ({
      ...produto,
      quantidadeRetiradaAnunciante: produto.quantidade,
      precoUnitario: produto.quantidade > 0 ? parseFloat(produto.valor) / parseFloat(produto.quantidade) : 0
    }))
    const idFormaDePagamento = result.payload.ultimaInteracao.idFormaDePagamento
    const index = formasPagamento.findIndex((pagamento) => pagamento.id.toUpperCase() === idFormaDePagamento.toUpperCase())
    const formaDePagamentoEntrega = {
      nome: formasPagamento[index].nome,
      id: formasPagamento[index].id
    }
    this.setState({ entregasRealizadas }, () => this.loadEntregaDetails())
    this.setState({ formaDePagamentoEntregaNome: formaDePagamentoEntrega.nome })
    this.setState({ formaDePagamentoEntregaId: formaDePagamentoEntrega.id })
    this.setState({ idReservaInteracao: result.payload.interacoes[result.payload.interacoes.length - 1].id })
    this.setState({ reservaProdutos: newReservaProdutos })
    this.setState({ reserva: result.payload, isLoadingDetalheEntrega: false })
  }

  loadEntregaDetails () {
    const isEntregaDone = this.isEntregaRealizada()
    if (isEntregaDone === true) {
      const { entregasRealizadas } = this.state

      if (Array.isArray(entregasRealizadas) && entregasRealizadas.length > 0) {
        const entrega = entregasRealizadas[0]

        this.setState({
          observacaoEntrega: entrega.observacoes,
          notafiscalEntrega: entrega.numeroNotaFiscal,
          responsavelEntrega: entrega.quemBuscou,
          ranking: entrega.ranking
        })
      }
    }
  }

  onChangeQuantidade (qtd, idProduto) {
    const { reservaProdutos } = this.state
    const index = reservaProdutos.findIndex((produto) => produto.idProduto === idProduto)
    const qtdReservada = parseFloat(qtd)

    reservaProdutos[index].quantidadeRetiradaAnunciante = qtdReservada >= 0.0 ? qtdReservada : parseFloat(0)

    this.setState({ reservaProdutos })
  }

  createObjectToApi () {
    const entregaObject = {
      entregaProdutos: this.state.reservaProdutos.map((produto) => ({
        id: null,
        idReservaProduto: produto.id,
        idProduto: produto.idProduto,
        nomeProduto: produto.nomeProduto,
        quantidade: produto.quantidadeRetiradaAnunciante
      })),
      realizada: moment().local().format(),
      quemBuscou: this.state.responsavelEntrega,
      numeroNotaFiscal: this.state.notafiscalEntrega,
      motivoDivergencia: this.state.observacaoEntrega,
      ranking: this.state.ranking,
      formasDePagamento: this.state.formasDePagamento,
      observacoes: this.state.observacaoEntrega,
      idReservaInteracao: this.state.idReservaInteracao
    }

    return entregaObject
  }

  onSubmit () {
    const { dispatch, saveReserva } = this.props
    dispatch(saveReserva(this.createObjectToApi()))
  }

  renderLinhaProduto (item) {
    const valorTotal = item.quantidadeRetiradaAnunciante * item.valor
    return (
      <tr key={item.idProduto}>
        <td>
          {item.nomeProduto} ({item.unidade})
        </td>
        <td className='text-center'>{item.quantidade}</td>
        <td>
          <InputNumber
            id={item.idProduto}
            valor={item.quantidadeRetiradaAnunciante}
            min={0}
            max={item.quantidade}
            precision={2}
            confirmacao={false}
            onChange={(e) => this.onChangeQuantidade(e, item.idProduto)}
          />
        </td>
        <td className='text-center'>R$ {parseFloat(item.valor).toFixed(2)}</td>
        <td className='text-center'>R$ {parseFloat(valorTotal).toFixed(2)}</td>
      </tr>
    )
  }

  calculateTotalPrice () {
    const { reservaProdutos, produtosAdicionais } = this.state
    let precoTotal = 0
    reservaProdutos.forEach((produto) => {
      precoTotal += produto.quantidadeRetiradaAnunciante * produto.valor
    })
    produtosAdicionais.forEach((produto) => {
      precoTotal += produto.valor
    })
    return `R$ ${parseFloat(precoTotal).toFixed(2)}`.replace('.', ',')
  }

  renderTabelaProduto (produtos) {
    return (
      <div className='table-responsive my-3'>
        <table className='table table-striped table-hover table-sm'>
          <thead>
            <tr>
              <th className='text-center' scope='col'>
                Nome Produto (UN)
              </th>
              <th className='text-center' scope='col'>
                Quantidade reservada
              </th>
              <th className='text-center' scope='col'>
                Quantidade entregue/retirada
              </th>
              <th className='text-center' scope='col'>
                Preço unitário
              </th>
              <th className='text-center' scope='col'>
                Preço total
              </th>
            </tr>
          </thead>
          <tbody>{produtos.filter((x) => x.quantidade > 0).map((produto) => this.renderLinhaProduto(produto))}</tbody>
        </table>
      </div>
    )
  }

  handleHide = () => {
    this.setState({
      isActive: false
    })
  }

  renderFailAlert () {
    const { isFailedToSaveEntrega } = this.props
    const { reservaProdutos } = this.state
    for (let i = 0; i < reservaProdutos.length; i++) {
      if (isFailedToSaveEntrega === true && reservaProdutos[i].qtdReservada !== reservaProdutos[i].quantidadeRetiradaAnunciante) {
        return <Alert variant='danger'>Indique nas observações o motivo da divergência na quantidade do produto reservado e entregue.</Alert>
      }
    }
  }

  selectAllProducts = () => {
    this.state.reservaProdutos.forEach((item) => this.onChangeQuantidade(item.quantidade, item.idProduto))
  }

  isEntregaRealizada () {
    const { entregasRealizadas } = this.state
    return Array.isArray(entregasRealizadas) && entregasRealizadas.length > 0
  }

  renderActionButtons () {
    const { pristine, submitting, invalid, isLoadingBtn, responsavelEntrega } = this.props
    const { checked } = this.state
    let nota
    if (isNullOrEmpty(this.state.notafiscalEntrega)) {
      if (checked) nota = false
      else nota = true
    } else {
      nota = false
    }
    if (this.isEntregaRealizada()) {
      return (
        <>
          <hr className='my-3' />
          <Alert variant='info'>
            <strong>Entrega já registrada. </strong>As informações apresentadas são apenas para fins de visualização.
          </Alert>
        </>
      )
    } else {
      return (
        <div className='row justify-content-end'>
          <div className='col-md-4 my-2'>
            <BotaoCancelar onClick={this.handleHide} label='Não vou retirar' />
          </div>
          <div className='col-md-3 my-2'>
            <BotaoSalvar onClick={() => this.onSubmit()} disabled={pristine || submitting || invalid || isLoadingBtn || isNullOrEmpty(this.state.responsavelEntrega) || nota} />
          </div>
        </div>
      )
    }
  }

  onChangeTextArea = (value) => this.setState({ observacaoEntrega: value })
  onChangeResponsavel = (value) => this.setState({ responsavelEntrega: value })
  onChangeNotaFiscal = (value) => this.setState({ notafiscalEntrega: value })
  handleOnChangeProduto = (idProduto, unidade, quantidade, precoUnitario) => {
    const { produtosAdicionais } = this.state
    const anuncioProdutos = produtosAdicionais.map((product) => {
      const { produto } = product
      if (produto.idProduto === idProduto) {
        return {
          produto,
          ...produto,
          quantidade,
          unidade,
          preco: precoUnitario,
          valor: precoUnitario * quantidade
        }
      } else {
        return {
          ...product
        }
      }
    })

    this.setState({ produtosAdicionais: anuncioProdutos })
    console.log(this.state.produtosAdicionais)
  }

  handleOnRemoveProduto = (idProduto) => {
    const { produtosAdicionais } = this.state
    this.setState({
      produtosAdicionais: produtosAdicionais.filter((ap) => ap.idProduto !== idProduto)
    })
  }

  handleEtapa1Dados = () => {
    return (
      <>
        <div className='row justify-content-between'>
          <h5>Verificar produtos reservados</h5>
          <button className='btn btn-success' size='md' onClick={() => this.selectAllProducts()}>
            Selecionar Todos Produtos
          </button>
        </div>
        {this.renderTabelaProduto(this.state.reservaProdutos)}
      </>
    )
  }

  handleEtapa2Adicional = () => {
    const RenderAddNew = () => {
      const { produtosCadastrados } = this.props
      const { produtosAdicionais } = this.state
      const SelectProdutos = () => (
        <>
          <h5>Inclusão de novos produtos:</h5>
          <ProdutosList
            anuncioProdutos={produtosAdicionais}
            produtos={produtosCadastrados}
            onChange={this.handleOnChangeProduto}
            onRemove={this.handleOnRemoveProduto}
            isPrecoProduto={false}
            onAdd={(idProduto) => {
              const produto = produtosCadastrados.find((x) => x.idProduto === idProduto)
              this.setState({ onAddMode: false })
              this.setState({ produtosAdicionais: [...produtosAdicionais, { produto, ...produto, quantidade: 1, unidade: produto.unidadeBase || 'KG', preco: 0, valor: 0 }] })
            }}
            isEstoqueProduto={false}
          />
          <p>
            <strong>Preço total entregue/retirada: {this.calculateTotalPrice()}</strong>
          </p>
        </>
      )
      return <SelectProdutos />
    }
    return <RenderAddNew />
  }

  handleEtapa3Fotos = () => {
    const RenderAddNew = () => {
      const { fotos } = this.state
      const SelectProdutos = () => (
        <>
          <h5>Inclusão de fotos dos produtos:</h5>
          <Dropzone
            multiple
            callback={(nome, type, content) => {
              this.setState({ fotos: [...fotos, { nome, type, content }] })
            }}
          />
          <div className='d-flex mt-5 flex-wrap row-cols-3 row-cols-sm-4 row-cols-md-5 row-cols-lg-6'>
            {fotos.map((foto, i) => (
              <div className='col position-relative'>
                <img src={foto.content} alt={foto.nome} className='img-fluid' />
                <Button
                  className='position-absolute text-white'
                  style={{ top: '-5px', right: '5px' }}
                  variant='danger'
                  onClick={() => {
                    this.setState({ fotos: fotos.filter((f, j) => j !== i) })
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            ))}
          </div>
        </>
      )
      return <SelectProdutos />
    }
    return <RenderAddNew />
  }

  handleEtapa4NotaFiscal = () => {
    return (
      <>
        <h5>Dados da coleta:</h5>
        <label>* Itens obrigatórios</label>
        <div className='form-group'>
          <label htmlFor='nome'>Responsável pela entrega/retirada: *</label>
          <textarea onChange={(e) => this.onChangeResponsavel(e.target.value)} rows={1} className='form-control' value={this.state.responsavelEntrega} required />
        </div>
        <div className='form-group'>
          <label htmlFor='nome'>Número da Nota Fiscal: *</label>
          <textarea
            onChange={(e) => this.onChangeNotaFiscal(e.target.value)}
            rows={1}
            className='form-control'
            value={this.state.notafiscalEntrega}
            required={!this.state.checked}
            disabled={this.state.checked}
          />
        </div>
        <div className='d-flex align-items-center mb-2'>
          <Switch className='mr-2' onChange={(e) => this.setState({ checked: e })} uncheckedIcon={false} checkedIcon={false} checked={this.state.checked || false} />
          Não possui nota
        </div>
        <div>
          <p>
            <strong>Forma de pagamento: {this.state.formaDePagamentoEntregaNome}</strong>
          </p>
        </div>
        <ObservationTextArea
          onChange={(e) => this.onChangeTextArea(e.target.value)}
          ref={this.textAreaRef}
          maxchars={500}
          labeltextarea='Observações: '
          textarearows='3'
          value={this.state.observacaoEntrega}
        />

        <div className='form-group'>
          <div>Como você classificaria a entrega?</div>
          <div className='rate' onChange={(e) => this.setState({ ranking: e.target.value })}>
            {[1, 2, 3, 4, 5].map((_, i) => {
              return (
                <>
                  <input key={i} type='radio' id={'star' + i} name='rate' value={i + 1} />
                  <label htmlFor={'star' + i} title='5 estrelas'>
                    {i + 1} estrelas
                  </label>
                </>
              )
            })}
          </div>
        </div>

        {this.renderActionButtons()}
      </>
    )
  }

  render () {
    const { connection, dispatch, isLoadingBtn, isPosting, isLoadingReserva } = this.props
    const { reserva, isLoadingDetalheEntrega } = this.state

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoadingDetalheEntrega || isLoadingBtn || isLoadingReserva) {
      return <Loading />
    }

    if (reserva === undefined || reserva === null || reserva.id === undefined) {
      return <>Não foi possível localizar o id informado</>
    }

    return this.state.isActive ? (
      <Screen back={{ to: 'venda/entrega-anunciante/listar', title: 'Entregas' }}>
        <Container>
          <div className='mt-4 mb-4 caixa'>
            {this.renderFailAlert()}
            <RenderInformacoes reserva={reserva} />
            <SectionHandler
              etapas={[
                {
                  etapa: 1,
                  callback: this.handleEtapa1Dados,
                  ativa: true
                },
                {
                  etapa: 2,
                  callback: this.handleEtapa4NotaFiscal,
                  ativa: false
                }
              ]}
              etapaInicial={1}
              finalizar={undefined}
            />
          </div>
        </Container>
      </Screen>
    ) : (
      <Screen back={{ to: 'venda/entrega-anunciante/listar', title: 'Entregas' }}>
        <div className='container margin'>
          <h4 className='margin'> Não vai retirar? Diga-nos o porquê: *</h4>
          <ObservationTextArea
            className='margin'
            onChange={(e) => this.onChangeTextArea(e.target.value)}
            ref={this.textAreaRef}
            maxchars={500}
            required
            textarearows='3'
            value={this.state.observacaoEntrega}
          />
          <label>* Item obrigatório</label>
          <div className={`row justify-content-end container-actions ${this.props.isInViewport ? 'container-actions-absolute' : 'container-actions-absolute'}`}>
            <div className='col-md-3 col-sm-2 my-2'>
              <button
                className='btn btn-success btn-block'
                onClick={() => this.onSubmit()}
                disabled={isPosting || this.state.observacaoEntrega === '' || this.state.observacaoEntrega === undefined}
              >
                {isPosting ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Salvar'}
              </button>
            </div>
          </div>
        </div>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.doacaoEntregaDoador,
  isLoadingReserva: state.doacaoReserva.isLoading,
  connection: state.main.connection,
  isLoading: state.doacaoContrato.isLoading,
  produtosCadastrados: state.produto.produtos
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadReservaById,
  saveReserva,
  loadProdutos
})

export default connect(mapStateToProps, mapDispatchToProps)(EntregaAnuncianteEditar)
