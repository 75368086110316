import { isEmptyArray } from 'formik'
import React, { useEffect, useState } from 'react'
import { Container, Button, ListGroup } from 'react-bootstrap/'
import { getNow } from 'util/date'
import { v4 as uuidv4 } from 'uuid'
import { DayOfWeekArray, DisplayDaysOfWeek, TipoHorarioEnum } from 'util/enums'
import Select from 'react-select'
import 'bootstrap-icons/font/bootstrap-icons.css'

const BotaoTipo = ({ tipo, setTipoCallback }) => (
  <>
    <button type='button' className={`button-selection btn-${tipo === TipoHorarioEnum.SEMANAL ? 'primary' : 'secondary'}`} onClick={() => setTipoCallback(TipoHorarioEnum.SEMANAL)}>
      Semanal
    </button>
    <button type='button' className={`button-selection btn-${tipo !== TipoHorarioEnum.SEMANAL ? 'primary' : 'secondary'}`} onClick={() => setTipoCallback(TipoHorarioEnum.DIARIO)}>
      Diária
    </button>
  </>
)

const FormDiario = ({ addCallback }) => {
  const [entrega, setEntrega] = useState({})
  const [reserva, setReserva] = useState({})
  const [days, setDays] = useState(DayOfWeekArray.map((item) => ({ ...item, checked: false })))

  const handleSemanaChange = (index) => {
    setDays(
      days.map((day, i) => {
        if (i === index) {
          return { ...day, checked: !day.checked }
        }
        return day
      })
    )
  }

  const handleAdicionar = () => {
    const daysMap = days
      .filter((x) => x.checked)
      .map((x) => {
        const dia = { inicioDia: x.value, fimDia: x.value }
        return { key: uuidv4(), dia: x.value, reserva: { ...reserva, ...dia }, entrega: { ...entrega, ...dia } }
      })

    if (daysMap.length > 0) {
      addCallback(daysMap)
    }
  }

  const diaSemanaerror = days.every((x) => x.checked === false)
  const inicioReservaError = !reserva.inicio
  const fimReservaError = !reserva.fim
  const inicioEntregaError = !entrega.inicio
  const fimEntregaError = !entrega.fim
  const anyError = inicioReservaError || fimReservaError || inicioEntregaError || fimEntregaError || diaSemanaerror

  const defaultFormClass = 'form-control py-1 w-100 nospin '
  const cnReservaInicio = defaultFormClass + (inicioReservaError ? 'invalid' : '')
  const cnReservaFim = defaultFormClass + (fimReservaError ? 'invalid' : '')
  const cnEntregaInicio = defaultFormClass + (inicioEntregaError ? 'invalid' : '')
  const cnEntregaFim = defaultFormClass + (fimEntregaError ? 'invalid' : '')

  const DayButton = ({ day, index }) => (
    <Button
      key={index}
      variant={day.checked ? 'primary' : 'outline-primary'}
      onClick={() => handleSemanaChange(index)}
      className='DayButton mr-2 mb-2'
    >
      {day.name}
    </Button>
  )

  return (
    <>
      <p className='margin-form'>Selecione um ou mais dias da semana:</p>
      <div className='d-flex flex-wrap'>
        {days.map((day, index) => (
          <DayButton day={day} index={index} />
        ))}
      </div>
      <h2 className='margin-form'>Reserva:</h2>
      <div className='margin-form'>
        Horário inicial da reserva: <input type='time' onChange={(e) => setReserva({ ...reserva, inicio: e.target.value })} className={cnReservaInicio} />
      </div>
      <div className='margin-form'>
        Horário final da reserva: <input type='time' onChange={(e) => setReserva({ ...reserva, fim: e.target.value })} className={cnReservaFim} />
      </div>
      <h2 className='margin-form2x'>Entrega:</h2>
      <div className='margin-form'>
        Horário inicial da entrega: <input type='time' onChange={(e) => setEntrega({ ...entrega, inicio: e.target.value })} className={cnEntregaInicio} />
      </div>
      <div className='margin-form'>
        Horário final da entrega: <input type='time' onChange={(e) => setEntrega({ ...entrega, fim: e.target.value })} className={cnEntregaFim} />
      </div>
      <button type='button' disabled={anyError} onClick={() => handleAdicionar()} className='button-confirm btn-success mx-auto mt-3'>
        Adicionar horário
      </button>
    </>
  )
}

const ListDiario = ({ horarios, removerCallback }) => {
  const Item = ({ horario: { key, entrega, reserva, dia } }) => {
    const handleRemover = () => {
      removerCallback(key)
    }

    return (
      <div className='margin-form'>
        <ListGroup.Item className='margin-form d-flex justify-content-between align-items-center'>
          <div>
            <strong>Reserva:</strong><br />
            {DisplayDaysOfWeek(dia)}: Das {reserva.inicio} até às {reserva.fim}<br /><br />
            <strong>Entrega:</strong><br />
            {DisplayDaysOfWeek(dia)}: Das {entrega.inicio} até às {entrega.fim}
          </div>
          <Button variant='danger' onClick={handleRemover} className='ml-auto'>
            <i className='bi bi-trash' />
          </Button>
        </ListGroup.Item>
      </div>
    )
  }

  if (isEmptyArray(horarios)) {
    return null
  }

  return (
    <div className='margin-form2x'>
      {horarios.length > 0 && <h3>Confira os dias e horários selecionados: </h3>}
      <ListGroup>
        {horarios.map((horario, index) => (
          <Item key={horario.key} horario={horario} />
        ))}
      </ListGroup>
    </div>
  )
}

const SelectOpcoesSemanal = ({ onChange, dados }) => (
  <>
    <div>
      <label htmlFor='dinicio'>Selecione o dia inicial:</label>
      <SelectDayOfWeek id='dinicio' placeholder='dia inicial' value={dados.inicioDia} onChange={(e) => onChange({ inicioDia: e.value })} />
    </div>
    <div className='margin-form'>
      <label htmlFor='inicio'>Hora inicial:</label>
      <input id='inicio' type='time' onChange={(e) => onChange({ inicio: e.target.value })} className='form-control py-1 w-100 nospin' />
    </div>
    <div className='margin-form2x'>
      <label htmlFor='dfim'>Selecione o dia final:</label>
      <SelectDayOfWeek id='dfim' placeholder='dia final' value={dados.fimDia} onChange={(e) => onChange({ fimDia: e.value })} />
    </div>
    <div className='margin-form'>
      <label htmlFor='fim'>Hora final:</label>
      <input id='fim' type='time' onChange={(e) => onChange({ fim: e.target.value })} className='form-control py-1 w-100 nospin' />
    </div>
  </>
)

const SelectDayOfWeek = ({ id, placeholder, value, onChange }) => (
  <Select
    isSearchable={false}
    id={id}
    name={id}
    placeholder={placeholder}
    options={DayOfWeekArray.map((item) => ({ value: item.value, label: item.name }))}
    onChange={onChange}
    value={DayOfWeekArray.filter((x) => x.value === value).map((item) => ({ value: item.value, label: item.name }))}
    className={'styled-select-unidade' + (DayOfWeekArray.every((x) => x.value !== value) ? ' invalid' : '')}
    styles={{
      // Fixes the overlapping problem of the component
      menu: (provided) => ({ ...provided, zIndex: 9999 })
    }}
  />
)

const FormSemanal = ({ addCallback }) => {
  const emptyState = {
    reserva: {},
    entrega: {}
  }
  const [horario, setHorario] = useState(emptyState)

  const isValid = ({ inicioDia, fimDia, inicio, fim }) => inicio != null || fim != null || inicioDia != null || fimDia != null
  const isValidBool = isValid(horario.reserva) && isValid(horario.entrega)

  const onClick = () => {
    addCallback([{ ...horario, key: getNow().toISOString() }])
    setHorario(emptyState)
  }

  const setReservaCallback = (obj) => {
    const reserva = { ...horario.reserva, ...obj }
    setHorario({ reserva, entrega: horario.entrega })
  }

  const setEntregaCallback = (obj) => {
    const entrega = { ...horario.entrega, ...obj }
    setHorario({ reserva: horario.reserva, entrega })
  }

  return (
    <>
      <h2>Reserva:</h2>
      <SelectOpcoesSemanal id='reserva' dados={horario.reserva} onChange={setReservaCallback} />
      <h2 className='margin-form2x'>Entrega:</h2>
      <SelectOpcoesSemanal id='entrega' dados={horario.entrega} onChange={setEntregaCallback} />
      <button type='button' onClick={onClick} disabled={!isValidBool} className='button-confirm btn-success mx-auto mt-3'>
        Adicionar horário
      </button>
    </>
  )
}

const ListSemanal = ({ horarios, removerCallback }) => {
  const Item = ({ horario: { key, entrega, reserva } }) => {
    const DisplayItem = (item) => (
      <>
        {DisplayDaysOfWeek(item.inicioDia)} às {item.inicio} até {DisplayDaysOfWeek(item.fimDia)} às {item.fim}
      </>
    )

    return (
      <ListGroup.Item className='margin-form2x d-flex justify-content-between align-items-center'>
        <div>
          <strong>Reserva:</strong>
          <p>{DisplayItem(reserva)}</p>
          <strong>Entrega:</strong>
          <p>{DisplayItem(entrega)}</p>
        </div>
        <Button variant='danger' onClick={() => removerCallback(key)}>
          <i className='bi bi-trash' />
        </Button>
      </ListGroup.Item>
    )
  }

  if (isEmptyArray(horarios)) {
    return null
  }

  return (
    <div className='margin-form2x'>
      <h3>Confira os dias e horários selecionados: </h3>
      <ListGroup>
        {horarios.map((horario) => (
          <Item key={horario.key} horario={horario} />
        ))}
      </ListGroup>
    </div>
  )
}

export function HorariosComponent ({ horarios, onChange, tipo, onChangeTipo }) {
  const [horariosInterno, setHorarios] = useState(() => {
    if (tipo === TipoHorarioEnum.SEMANAL) {
      return { semanal: horarios, diario: [] }
    } else {
      return { semanal: [], diario: horarios }
    }
  })
  const { diario, semanal } = horariosInterno

  const setHorariosSemanaisCallback = (horarios) => {
    setHorarios({ semanal: [...semanal, ...horarios], diario })
  }
  const removeHorarioSemanal = (itemKey) => {
    setHorarios({ diario, semanal: [...semanal.filter((s) => s.key !== itemKey)] })
  }

  const setHorariosDiariosCallback = (horarios) => {
    setHorarios({ semanal, diario: [...diario, ...horarios] })
  }
  const removeHorarioDiario = (itemKey) => {
    setHorarios({ diario: [...diario.filter((s) => s.key !== itemKey)], semanal })
  }

  useEffect(() => {
    if (tipo === TipoHorarioEnum.SEMANAL) {
      onChange(horariosInterno.semanal)
    } else {
      onChange(horariosInterno.diario)
    }
  }, [horariosInterno, tipo])

  const DisplaySemanal = () => (
    <>
      <FormSemanal addCallback={setHorariosSemanaisCallback} />
      <ListSemanal horarios={semanal} removerCallback={removeHorarioSemanal} />
    </>
  )

  const DisplayDiario = () => (
    <>
      <FormDiario addCallback={setHorariosDiariosCallback} />
      <ListDiario horarios={diario} removerCallback={removeHorarioDiario} />
    </>
  )

  return (
    <Container>
      <BotaoTipo tipo={tipo} setTipoCallback={onChangeTipo} />
      {tipo === TipoHorarioEnum.SEMANAL ? <DisplaySemanal /> : <DisplayDiario />}
    </Container>
  )
}
