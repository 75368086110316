import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Card, Container } from 'react-bootstrap'
import { isNotNullOrEmpty } from 'util/utils'
import { updateEstoque, loadProdutos } from 'store/produto/actions'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

function produtoPropsToState (produto) {
  return {
    idProduto: produto.idProduto,
    nomeProduto: produto.nomeProduto,
    unidade: isNotNullOrEmpty(produto.unidade) ? produto.unidade : produto.unidadeBase,
    unidadeBase: produto.unidadeBase,
    quantidade: produto.quantidade,
    estoque: produto.estoque || 0,
    numero: produto.numero,
    tipo: produto.tipo,
    preco: produto.preco || produto.precoBase,
    lote: produto.lote || (produto.ultimaModificacao && produto.ultimaModificacao.numLote),
    vencimento: produto.vencimento
      ? dayjs(produto.vencimento).format('YYYY-MM-DDTHH:mm')
      : produto.ultimaModificacao && produto.ultimaModificacao.validade
      ? dayjs(produto.ultimaModificacao.validade).format('YYYY-MM-DDTHH:mm')
      : undefined,
    invalid: false,
    ...produto
  }
}

function BoxProduto ({ dispatch, produto, hide, onEdit, hidePrice }) {
  const [dados, setDados] = useState(produtoPropsToState(produto))
  useEffect(() => {
    setDados(produtoPropsToState(produto))
  }, [produto])

  const zerar = async () => {
    const produtoData = { ...dados }
    produtoData.estoque = 0
    await dispatch(updateEstoque(produtoData))
    await dispatch(loadProdutos())
  }

  const isVencido = (validade) => validade && dayjs().isBefore(dayjs(validade).add(1, 'day'), 'day')

  const getCssDoCard = (validade, diasParaVencer) => {
    if (!validade) return 'bg-dark text-white'
    if (diasParaVencer < 0) return 'bg-danger text-white'
    if (diasParaVencer < 3) return 'bg-semi-danger text-black'
    if (diasParaVencer < 7) return 'bg-warning text-black'

    return 'bg-dark text-white'
  }

  const RenderCard = ({ dados }) => {
    const validade = dados.vencimento ? dayjs(dados.vencimento) : dados.ultimaModificacao ? dayjs(dados.ultimaModificacao.validade) : undefined
    const lote = dados.numLote || (dados.ultimaModificacao && dados.ultimaModificacao.numLote)
    const quantidade = dados.quantidade || (dados.ultimaModificacao && dados.ultimaModificacao.quantidade)
    const estoque = dados.estoque || (dados.ultimaModificacao && dados.ultimaModificacao.estoque)
    const preco = dados.preco || (dados.ultimaModificacao && dados.ultimaModificacao.preco)
    const diasParaVencer = validade ? dayjs(validade).diff(dayjs(), 'days') || 0 : 0
    const cssBox = getCssDoCard(validade, diasParaVencer)
    const color = cssBox === 'bg-danger text-white' ? 'danger' : cssBox === 'bg-semi-danger text-black' ? 'semi-danger' : cssBox === 'bg-warning text-black' ? 'warning' : 'dark'

    return (
      <Card
        key={`card-produto-${dados.idProduto}`}
        className='mb-3 shadow-xl'
        style={{
          maxWidth: '305px',
          minWidth: '265px',
          border: '.5px solid #eee',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)'
        }}
      >
        <Card.Header className={cssBox} as='h6'>
          <div className='pt-2'>{dados.nomeProduto || dados.produto.nomeProduto}</div>
        </Card.Header>
        <Card.Body>
          <Container className='d-flex flex-column h-100'>
            <Row className='text-secondary pl-1 mb-2'>
              <Col>Unidade</Col>
              <Col>{dados.unidade || '??'}</Col>
            </Row>
            <Row className='text-secondary pl-1 mb-2'>
              <Col>Quantidade</Col>
              <Col>{quantidade ? quantidade.toLocaleString('pt-br') : estoque ? estoque.toLocaleString('pt-br') : 'Sem Estoque'}</Col>
            </Row>
            {!hidePrice && (
              <Row className='text-secondary pl-1 mb-2'>
                <Col>Preço</Col>
                <Col>{preco || dados.preco ? dados.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Sem Preço'}</Col>
              </Row>
            )}
            {lote && (
              <Row className='text-secondary pl-1 mb-2'>
                <Col>Lote</Col>
                <Col>{dados.numLote || dados.lote || 'Sem Lote'}</Col>
              </Row>
            )}
            <Row className='text-secondary pl-1 mb-2'>
              <Col>Validade</Col>
              <Col>{validade ? validade.format('DD/MM/YYYY') : 'Sem Validade'}</Col>
            </Row>
            {diasParaVencer > 0 && (
              <Row className='text-secondary pl-1 mb-2'>
                <Col>Dias para vencer</Col>
                <Col>{diasParaVencer}</Col>
              </Row>
            )}
            <Row className='text-secondary justify-content-between mb-2 px-2' />
            {!hide && (
              <div className='mt-auto'>
                <Button
                  onClick={() => onEdit({ ...dados, validade: validade ? validade.format('YYYY-MM-DD') : undefined, lote })}
                  variant={color}
                  block
                  style={{ fontSize: 14 }}
                  className='py-2 mb-3'
                  // disabled={vencido}
                >
                  <b>Editar</b>
                </Button>
                <Button onClick={zerar} disabled={isVencido(validade)} block style={{ fontSize: 14 }} className='py-2' variant={`outline-${color}`}>
                  <b>Zerar</b>
                </Button>
              </div>
            )}
          </Container>
        </Card.Body>
      </Card>
    )
  }
  return <RenderCard dados={dados} hide={!!hide} hidePrice={hidePrice} />
}

BoxProduto.propTypes = {
  produto: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hidePrice: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(BoxProduto)
