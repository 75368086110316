import React, { useState } from 'react'
import Steps from 'components/produto/steps'
import { useHistory } from 'react-router-dom'
import { Screen } from 'components/style'
import { connect } from 'react-redux'
import { updateEstoque } from 'store/produto/actions'

const { Step1, Step2, Step3, Step4, Step5 } = Steps

function EditarProduto ({ dispatch }) {
  const produtoJson = localStorage.getItem('produto')
  const produtoBase = JSON.parse(produtoJson)
  const rawProduct = produtoBase || {}
  const aditionalInfo = { lote: rawProduct.lote || '', valor: rawProduct.valor || 0, validade: rawProduct.validade || '' }
  const obj = { ...rawProduct, ...aditionalInfo, updating: false }
  const [position, setPosition] = useState(1)
  const [produto, setProduto] = useState(obj)
  const positions = [produto.updating === undefined, (produto.medida && !produto.medida.length) || !produto.estoque, !produto.preco, !produto.lote, !produto.validade]
  const history = useHistory()
  const isFormInvalid = positions.some((validation) => !!validation)

  function next (payload) {
    setProduto({ ...produto, ...payload })
    setPosition(position + 1)
  }
  function back (payload) {
    setProduto({ ...produto, ...payload })
    setPosition(position - 1)
  }

  async function finish (payload) {
    const prod = { ...produto, ...payload }
    setProduto(prod)
    if (isFormInvalid) {
      const index = positions.findIndex((validation) => validation)
      setPosition(index + 1)
      return
    }
    await dispatch(updateEstoque(prod))
    history.push('/produtos')
  }
  function CurrentStep ({ isFormValid }) {
    switch (position) {
      case 1:
        return <Step1 next={next} nome={produto.nomeProduto} updating={produto.updating} />
      case 2:
        return <Step2 next={next} nome={produto.nomeProduto} back={back} unidade={produto.unidade} estoque={produto.estoque} />
      case 3:
        return <Step3 next={next} nome={produto.nomeProduto} back={back} valor={produto.preco} />
      case 4:
        return <Step4 next={next} nome={produto.nomeProduto} back={back} lote={produto.lote} updating={produto.updating} />
      case 5:
        return <Step5 next={finish} nome={produto.nomeProduto} back={back} validade={produto.validade} updating={produto.updating} isFormValid={isFormValid} />
      default:
        return <></>
    }
  }
  function Positions () {
    return positions.map((callbackError, i) => {
      let classes = ''
      try {
        if (!callbackError) classes += ' button-circle '
        else classes += ' button-circleRed '
      } catch (e) {
        classes += ' button-circle '
      }
      if (i !== 4) classes += 'mr-1 mr-sm-3'
      return (
        <div onClick={() => setPosition(i + 1)} key={i} style={{ border: '2px solid #eee', cursor: 'pointer' }} className={classes} variant='outlined'>
          <div className=' '>{i + 1}</div>
        </div>
      )
    })
  }

  return (
    <Screen back={{ to: '/produtos', title: 'Menu' }}>
      {/* <div style={{ overflowX: 'hidden' }}>
        <div className="d-flex justify-content-center flex-wrap mt-4">
          <Positions />
        </div>
        <div className="mt-4 mx-3">
          <CurrentStep position={position} />
        </div>
      </div> */}
    </Screen>
  )
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(EditarProduto)
